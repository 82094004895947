// 👇 optional -> set custom breakpoints
// $breakpoints: (
//   xs: 32rem,
//   sm: 48rem,
//   md: 64rem,
//   lg: 80rem,
//   xl: 90rem
// );

@import 'base';
/*! purgecss start ignore */
@import 'custom-style';
@import "components/_1_accordion.scss";
@import "components/_1_anim-menu-btn.scss";
@import "components/_1_animated-headline.scss";
@import "components/_1_bg-gradients.scss";
@import "components/_1_countup.scss";
@import "components/_1_feature-v7.scss";
@import "components/_1_hiding-nav.scss";
@import "components/_1_immersive-section-transition.scss";
@import "components/_1_list.scss";
@import "components/_1_masonry.scss";
@import "components/_1_modal-window.scss";
@import "components/_1_rating.scss";
@import "components/_1_reveal-effects.scss";
@import "components/_1_scrolling-animations.scss";
@import "components/_1_section-divider.scss";
@import "components/_1_video_feature.scss";
@import "components/_2_flexi-header.scss";
@import "components/_2_floating-navigation.scss";
@import "components/_2_image-zoom.scss";
@import "components/_3_overscroll-gallery.scss";
/*! purgecss end ignore */
