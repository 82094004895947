/* --------------------------------

File#: _1_hiding-nav
Title: Auto Hiding Navigation
Descr: A Navigation container that auto-hides when the user scrolls down, and is revealed when they scrolls back up
Usage: codyhouse.co/license

-------------------------------- */

.hide-nav {
  position: sticky !important;
  top: 27px;
  will-change: transform;
  transition: transform 0.3s, background-color 0.3s;
  position: absolute !important;
  left: 0;
  right: 0;
  margin: 0 auto !important; 
}

.hide-nav--fixed {
  background-color: rgba(255,255,255,.5);
}

.hide-nav--has-bg {
  background-color: var(--color-bg);
}

.hide-nav--off-canvas { // main header is off-canvas
  // add off-canvas custom style (if any)
}
