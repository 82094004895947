:root {
  --btn-font-size: 1em;
  --btn-padding-x: var(--space-sm);
  --btn-padding-y: var(--space-xxs);
  --btn-radius: 0.25em;
}

.btn {
  cursor: pointer;
  font-weight: 500;
  text-transform: uppercase;
  text-decoration: none;
  line-height: 1.2;
  @include fontSmooth;
  box-shadow: var(--shadow-xs);
  transition: all 0.2s ease;
  will-change: transform;

  &:hover {
    box-shadow: var(--shadow-sm);
  }

  &:focus {
    box-shadow: 0px 0px 0px 2px alpha(var(--color-contrast-higher), 0.15);
    outline: none;
  }

  &:active {
    transform: translateY(2px);
  }
}

// themes
.btn--primary {
  background: var(--color-primary);
  color: var(--color-white);

  &:hover {
    background: var(--color-primary-dark);
  }

  &:focus {
    box-shadow: 0px 0px 0px 2px alpha(var(--color-primary), 0.2);
  }
}

.btn--subtle {
  background: var(--color-contrast-lower);
  color: var(--color-contrast-higher);
}

.btn--accent {
  background: var(--color-accent);
  color: var(--color-white);

  &:hover {
    background: var(--color-accent-dark);
  }

  &:focus {
    box-shadow: 0px 0px 0px 2px alpha(var(--color-accent), 0.2);
  }
}

// feedback
.btn--disabled, .btn[disabled], .btn[readonly] {
  opacity: 0.6;
  cursor: not-allowed;
}

// size
.btn--sm {
  font-size: 0.8em;
}

.btn--md {
  font-size: 1.2em;
}

.btn--lg {
  font-size: 1.4em;
}
