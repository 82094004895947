:root, [data-theme="default"] {
  // main
  @include defineColorHSL(--color-primary-darker, 181, 83%, 5%);
  @include defineColorHSL(--color-primary-dark, 181, 83%, 15%);
  @include defineColorHSL(--color-primary, 181, 83%, 25%);
  @include defineColorHSL(--color-primary-light, 181, 83%, 35%);
  @include defineColorHSL(--color-primary-lighter, 181, 83%, 45%);

  @include defineColorHSL(--color-accent-darker, 49, 100%, 42%);
  @include defineColorHSL(--color-accent-dark, 49, 100%, 52%);
  @include defineColorHSL(--color-accent, 49, 100%, 62%);
  @include defineColorHSL(--color-accent-light, 49, 100%, 72%);
  @include defineColorHSL(--color-accent-lighter, 49, 100%, 82%);

  @include defineColorHSL(--color-secondary-darker, 32, 93%, 35%);
  @include defineColorHSL(--color-secondary-dark, 32, 93%, 45%);
  @include defineColorHSL(--color-secondary, 32, 93%, 55%);
  @include defineColorHSL(--color-secondary-light, 32, 93%, 65%);
  @include defineColorHSL(--color-secondary-lighter, 32, 93%, 75%);

  @include defineColorHSL(--color-tertiary-darker, 185, 58%, 2%);
  @include defineColorHSL(--color-tertiary-dark, 185, 58%, 12%);
  @include defineColorHSL(--color-tertiary, 185, 58%, 22%);
  @include defineColorHSL(--color-tertiary-light, 185, 58%, 32%);
  @include defineColorHSL(--color-tertiary-lighter, 185, 58%, 42%);

  @include defineColorHSL(--color-black, 240, 8%, 12%);
  @include defineColorHSL(--color-white, 0, 0%, 100%);

  // feedback
  @include defineColorHSL(--color-warning-darker, 46, 100%, 47%);
  @include defineColorHSL(--color-warning-dark, 46, 100%, 50%);
  @include defineColorHSL(--color-warning, 46, 100%, 61%);
  @include defineColorHSL(--color-warning-light, 46, 100%, 71%);
  @include defineColorHSL(--color-warning-lighter, 46, 100%, 80%);

  @include defineColorHSL(--color-success-darker, 94, 48%, 42%);
  @include defineColorHSL(--color-success-dark, 94, 48%, 48%);
  @include defineColorHSL(--color-success, 94, 48%, 56%);
  @include defineColorHSL(--color-success-light, 94, 48%, 65%);
  @include defineColorHSL(--color-success-lighter, 94, 48%, 74%);

  @include defineColorHSL(--color-error-darker, 349, 75%, 36%);
  @include defineColorHSL(--color-error-dark, 349, 75%, 44%);
  @include defineColorHSL(--color-error, 349, 75%, 51%);
  @include defineColorHSL(--color-error-light, 349, 75%, 59%);
  @include defineColorHSL(--color-error-lighter, 349, 75%, 67%);

  // color contrasts
  @include defineColorHSL(--color-bg, 0, 0%, 100%);
  @include defineColorHSL(--color-contrast-lower, 0, 0%, 95%);
  @include defineColorHSL(--color-contrast-low, 240, 1%, 83%);
  @include defineColorHSL(--color-contrast-medium, 240, 1%, 48%);
  @include defineColorHSL(--color-contrast-high, 240, 4%, 20%);
  @include defineColorHSL(--color-contrast-higher, 240, 8%, 12%);
}

// background color
.bg-secondary-darker { background-color: alpha(var(--color-secondary-darker), var(--bg-o, 1)); }
.bg-secondary-dark { background-color: alpha(var(--color-secondary-dark), var(--bg-o, 1)); }
.bg-secondary { background-color: alpha(var(--color-secondary), var(--bg-o, 1)); }
.bg-secondary-light { background-color: alpha(var(--color-secondary-light), var(--bg-o, 1)); }
.bg-secondary-lighter { background-color: alpha(var(--color-secondary-lighter), var(--bg-o, 1)); }
.bg-tertiary-darker { background-color: alpha(var(--color-tertiary-darker), var(--bg-o, 1)); }
.bg-tertiary-dark { background-color: alpha(var(--color-tertiary-dark), var(--bg-o, 1)); }
.bg-tertiary { background-color: alpha(var(--color-tertiary), var(--bg-o, 1)); }
.bg-tertiary-light { background-color: alpha(var(--color-tertiary-light), var(--bg-o, 1)); }
.bg-tertiary-lighter { background-color: alpha(var(--color-tertiary-lighter), var(--bg-o, 1)); }

// border color
.border-secondary-darker { border-color: alpha(var(--color-secondary-darker), var(--bg-o, 1)); }
.border-secondary-dark { border-color: alpha(var(--color-secondary-dark), var(--bg-o, 1)); }
.border-secondary { border-color: alpha(var(--color-secondary), var(--bg-o, 1)); }
.border-secondary-light { border-color: alpha(var(--color-secondary-light), var(--bg-o, 1)); }
.border-secondary-lighter { border-color: alpha(var(--color-secondary-lighter), var(--bg-o, 1)); }
.border-tertiary-darker { border-color: alpha(var(--color-tertiary-darker), var(--bg-o, 1)); }
.border-tertiary-dark { border-color: alpha(var(--color-tertiary-dark), var(--bg-o, 1)); }
.border-tertiary { border-color: alpha(var(--color-tertiary), var(--bg-o, 1)); }
.border-tertiary-light { border-color: alpha(var(--color-tertiary-light), var(--bg-o, 1)); }
.border-tertiary-lighter { border-color: alpha(var(--color-tertiary-lighter), var(--bg-o, 1)); }