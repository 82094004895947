@if variable-exists('cody-base-partials') == false or index($cody-base-partials, 'util') {
  // --------------------------------

  // Flexbox

  // --------------------------------

  .flex { display: flex; }
  .inline-flex { display: inline-flex; }
  .flex-wrap { flex-wrap: wrap; }
  .flex-column { flex-direction: column; }
  .flex-column-reverse { flex-direction: column-reverse; }
  .flex-row { flex-direction: row; }
  .flex-row-reverse { flex-direction: row-reverse; }
  .flex-center { justify-content: center; align-items: center; }

  // flex items
  .flex-grow { flex-grow: 1; }
  .flex-grow-0 { flex-grow: 0; }
  .flex-shrink { flex-shrink: 1; }
  .flex-shrink-0 { flex-shrink: 0; }
  .flex-basis-0 { flex-basis: 0; }

  // --------------------------------

  // Justify Content

  // --------------------------------

  .justify-start { justify-content: flex-start; }
  .justify-end { justify-content: flex-end; }
  .justify-center { justify-content: center; }
  .justify-between { justify-content: space-between; }

  // --------------------------------

  // Align Items

  // --------------------------------

  .items-center { align-items: center; }
  .items-start { align-items: flex-start; }
  .items-end { align-items: flex-end; }
  .items-baseline { align-items: baseline; }

  // --------------------------------

  // Order

  // --------------------------------

  .order-1 { order: 1; }
  .order-2 { order: 2; }
  .order-3 { order: 3; }

  // --------------------------------

  // Aspect Ratio

  // --------------------------------

  [class^="aspect-ratio"], [class*=" aspect-ratio"] {
    --aspect-ratio: calc(16/9);
    position: relative;
    height: 0;
    padding-bottom: calc(100%/(var(--aspect-ratio)));

    > * {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      &:not(iframe) {
        object-fit: cover;
      }
    }
  }

  .aspect-ratio-16\:9 { --aspect-ratio: calc(16/9); }
  .aspect-ratio-3\:2  { --aspect-ratio: calc(3/2); }
  .aspect-ratio-4\:3  { --aspect-ratio: calc(4/3); }
  .aspect-ratio-5\:4  { --aspect-ratio: calc(5/4); }
  .aspect-ratio-1\:1  { --aspect-ratio: calc(1/1); }
  .aspect-ratio-4\:5  { --aspect-ratio: calc(4/5); }
  .aspect-ratio-3\:4  { --aspect-ratio: calc(3/4); }
  .aspect-ratio-2\:3  { --aspect-ratio: calc(2/3); }
  .aspect-ratio-9\:16 { --aspect-ratio: calc(9/16); }

  // --------------------------------

  // Display

  // --------------------------------

  .block { display: block; }
  .inline-block { display: inline-block; }
  .inline { display: inline; }

  // --------------------------------

  // Space unit

  // --------------------------------

  .space-unit-rem { --space-unit: 1rem; }
  .space-unit-em  { --space-unit: 1em; }
  .space-unit-px  { --space-unit: 16px; }

  // --------------------------------

  // Margin

  // --------------------------------

  .margin-xxxxs { margin: var(--space-xxxxs); }
  .margin-xxxs  { margin: var(--space-xxxs); }
  .margin-xxs   { margin: var(--space-xxs); }
  .margin-xs    { margin: var(--space-xs); }
  .margin-sm    { margin: var(--space-sm); }
  .margin-md    { margin: var(--space-md); }
  .margin-lg    { margin: var(--space-lg); }
  .margin-xl    { margin: var(--space-xl); }
  .margin-xxl   { margin: var(--space-xxl); }
  .margin-xxxl  { margin: var(--space-xxxl); }
  .margin-xxxxl { margin: var(--space-xxxxl); }
  .margin-auto  { margin: auto; }
  .margin-0     { margin: 0; }

  .margin-top-xxxxs { margin-top: var(--space-xxxxs); }
  .margin-top-xxxs  { margin-top: var(--space-xxxs); }
  .margin-top-xxs   { margin-top: var(--space-xxs); }
  .margin-top-xs    { margin-top: var(--space-xs); }
  .margin-top-sm    { margin-top: var(--space-sm); }
  .margin-top-md    { margin-top: var(--space-md); }
  .margin-top-lg    { margin-top: var(--space-lg); }
  .margin-top-xl    { margin-top: var(--space-xl); }
  .margin-top-xxl   { margin-top: var(--space-xxl); }
  .margin-top-xxxl  { margin-top: var(--space-xxxl); }
  .margin-top-xxxxl { margin-top: var(--space-xxxxl); }
  .margin-top-auto  { margin-top: auto; }
  .margin-top-0     { margin-top: 0; }

  .margin-bottom-xxxxs { margin-bottom: var(--space-xxxxs); }
  .margin-bottom-xxxs  { margin-bottom: var(--space-xxxs); }
  .margin-bottom-xxs   { margin-bottom: var(--space-xxs); }
  .margin-bottom-xs    { margin-bottom: var(--space-xs); }
  .margin-bottom-sm    { margin-bottom: var(--space-sm); }
  .margin-bottom-md    { margin-bottom: var(--space-md); }
  .margin-bottom-lg    { margin-bottom: var(--space-lg); }
  .margin-bottom-xl    { margin-bottom: var(--space-xl); }
  .margin-bottom-xxl   { margin-bottom: var(--space-xxl); }
  .margin-bottom-xxxl  { margin-bottom: var(--space-xxxl); }
  .margin-bottom-xxxxl { margin-bottom: var(--space-xxxxl); }
  .margin-bottom-auto  { margin-bottom: auto; }
  .margin-bottom-0     { margin-bottom: 0; }

  .margin-right-xxxxs { margin-right: var(--space-xxxxs); }
  .margin-right-xxxs  { margin-right: var(--space-xxxs); }
  .margin-right-xxs   { margin-right: var(--space-xxs); }
  .margin-right-xs    { margin-right: var(--space-xs); }
  .margin-right-sm    { margin-right: var(--space-sm); }
  .margin-right-md    { margin-right: var(--space-md); }
  .margin-right-lg    { margin-right: var(--space-lg); }
  .margin-right-xl    { margin-right: var(--space-xl); }
  .margin-right-xxl   { margin-right: var(--space-xxl); }
  .margin-right-xxxl  { margin-right: var(--space-xxxl); }
  .margin-right-xxxxl { margin-right: var(--space-xxxxl); }
  .margin-right-auto  { margin-right: auto; }
  .margin-right-0     { margin-right: 0; }

  .margin-left-xxxxs { margin-left: var(--space-xxxxs); }
  .margin-left-xxxs  { margin-left: var(--space-xxxs); }
  .margin-left-xxs   { margin-left: var(--space-xxs); }
  .margin-left-xs    { margin-left: var(--space-xs); }
  .margin-left-sm    { margin-left: var(--space-sm); }
  .margin-left-md    { margin-left: var(--space-md); }
  .margin-left-lg    { margin-left: var(--space-lg); }
  .margin-left-xl    { margin-left: var(--space-xl); }
  .margin-left-xxl   { margin-left: var(--space-xxl); }
  .margin-left-xxxl  { margin-left: var(--space-xxxl); }
  .margin-left-xxxxl { margin-left: var(--space-xxxxl); }
  .margin-left-auto  { margin-left: auto; }
  .margin-left-0     { margin-left: 0; }

  .margin-x-xxxxs { margin-left: var(--space-xxxxs); margin-right: var(--space-xxxxs); }
  .margin-x-xxxs  { margin-left: var(--space-xxxs); margin-right: var(--space-xxxs); }
  .margin-x-xxs   { margin-left: var(--space-xxs); margin-right: var(--space-xxs); }
  .margin-x-xs    { margin-left: var(--space-xs); margin-right: var(--space-xs); }
  .margin-x-sm    { margin-left: var(--space-sm); margin-right: var(--space-sm); }
  .margin-x-md    { margin-left: var(--space-md); margin-right: var(--space-md); }
  .margin-x-lg    { margin-left: var(--space-lg); margin-right: var(--space-lg); }
  .margin-x-xl    { margin-left: var(--space-xl); margin-right: var(--space-xl); }
  .margin-x-xxl   { margin-left: var(--space-xxl); margin-right: var(--space-xxl); }
  .margin-x-xxxl  { margin-left: var(--space-xxxl); margin-right: var(--space-xxxl); }
  .margin-x-xxxxl { margin-left: var(--space-xxxxl); margin-right: var(--space-xxxxl); }
  .margin-x-auto  { margin-left: auto; margin-right: auto; }
  .margin-x-0     { margin-left: 0; margin-right: 0; }

  .margin-y-xxxxs { margin-top: var(--space-xxxxs); margin-bottom: var(--space-xxxxs); }
  .margin-y-xxxs  { margin-top: var(--space-xxxs); margin-bottom: var(--space-xxxs); }
  .margin-y-xxs   { margin-top: var(--space-xxs); margin-bottom: var(--space-xxs); }
  .margin-y-xs    { margin-top: var(--space-xs); margin-bottom: var(--space-xs); }
  .margin-y-sm    { margin-top: var(--space-sm); margin-bottom: var(--space-sm); }
  .margin-y-md    { margin-top: var(--space-md); margin-bottom: var(--space-md); }
  .margin-y-lg    { margin-top: var(--space-lg); margin-bottom: var(--space-lg); }
  .margin-y-xl    { margin-top: var(--space-xl); margin-bottom: var(--space-xl); }
  .margin-y-xxl   { margin-top: var(--space-xxl); margin-bottom: var(--space-xxl); }
  .margin-y-xxxl  { margin-top: var(--space-xxxl); margin-bottom: var(--space-xxxl); }
  .margin-y-xxxxl { margin-top: var(--space-xxxxl); margin-bottom: var(--space-xxxxl); }
  .margin-y-auto  { margin-top: auto; margin-bottom: auto; }
  .margin-y-0     { margin-top: 0; margin-bottom: 0; }

  // --------------------------------

  // Padding

  // --------------------------------

  .padding-xxxxs { padding: var(--space-xxxxs); }
  .padding-xxxs  { padding: var(--space-xxxs); }
  .padding-xxs   { padding: var(--space-xxs); }
  .padding-xs    { padding: var(--space-xs); }
  .padding-sm    { padding: var(--space-sm); }
  .padding-md    { padding: var(--space-md); }
  .padding-lg    { padding: var(--space-lg); }
  .padding-xl    { padding: var(--space-xl); }
  .padding-xxl   { padding: var(--space-xxl); }
  .padding-xxxl  { padding: var(--space-xxxl); }
  .padding-xxxxl { padding: var(--space-xxxxl); }
  .padding-0     { padding: 0; }
  .padding-component { padding: var(--component-padding); }

  .padding-top-xxxxs { padding-top: var(--space-xxxxs); }
  .padding-top-xxxs  { padding-top: var(--space-xxxs); }
  .padding-top-xxs   { padding-top: var(--space-xxs); }
  .padding-top-xs    { padding-top: var(--space-xs); }
  .padding-top-sm    { padding-top: var(--space-sm); }
  .padding-top-md    { padding-top: var(--space-md); }
  .padding-top-lg    { padding-top: var(--space-lg); }
  .padding-top-xl    { padding-top: var(--space-xl); }
  .padding-top-xxl   { padding-top: var(--space-xxl); }
  .padding-top-xxxl  { padding-top: var(--space-xxxl); }
  .padding-top-xxxxl { padding-top: var(--space-xxxxl); }
  .padding-top-0     { padding-top: 0; }
  .padding-top-component { padding-top: var(--component-padding); }

  .padding-bottom-xxxxs { padding-bottom: var(--space-xxxxs); }
  .padding-bottom-xxxs  { padding-bottom: var(--space-xxxs); }
  .padding-bottom-xxs   { padding-bottom: var(--space-xxs); }
  .padding-bottom-xs    { padding-bottom: var(--space-xs); }
  .padding-bottom-sm    { padding-bottom: var(--space-sm); }
  .padding-bottom-md    { padding-bottom: var(--space-md); }
  .padding-bottom-lg    { padding-bottom: var(--space-lg); }
  .padding-bottom-xl    { padding-bottom: var(--space-xl); }
  .padding-bottom-xxl   { padding-bottom: var(--space-xxl); }
  .padding-bottom-xxxl  { padding-bottom: var(--space-xxxl); }
  .padding-bottom-xxxxl { padding-bottom: var(--space-xxxxl); }
  .padding-bottom-0     { padding-bottom: 0; }
  .padding-bottom-component { padding-bottom: var(--component-padding); }

  .padding-right-xxxxs { padding-right: var(--space-xxxxs); }
  .padding-right-xxxs  { padding-right: var(--space-xxxs); }
  .padding-right-xxs   { padding-right: var(--space-xxs); }
  .padding-right-xs    { padding-right: var(--space-xs); }
  .padding-right-sm    { padding-right: var(--space-sm); }
  .padding-right-md    { padding-right: var(--space-md); }
  .padding-right-lg    { padding-right: var(--space-lg); }
  .padding-right-xl    { padding-right: var(--space-xl); }
  .padding-right-xxl   { padding-right: var(--space-xxl); }
  .padding-right-xxxl  { padding-right: var(--space-xxxl); }
  .padding-right-xxxxl { padding-right: var(--space-xxxxl); }
  .padding-right-0     { padding-right: 0; }
  .padding-right-component { padding-right: var(--component-padding); }

  .padding-left-xxxxs { padding-left: var(--space-xxxxs); }
  .padding-left-xxxs  { padding-left: var(--space-xxxs); }
  .padding-left-xxs   { padding-left: var(--space-xxs); }
  .padding-left-xs    { padding-left: var(--space-xs); }
  .padding-left-sm    { padding-left: var(--space-sm); }
  .padding-left-md    { padding-left: var(--space-md); }
  .padding-left-lg    { padding-left: var(--space-lg); }
  .padding-left-xl    { padding-left: var(--space-xl); }
  .padding-left-xxl   { padding-left: var(--space-xxl); }
  .padding-left-xxxl  { padding-left: var(--space-xxxl); }
  .padding-left-xxxxl { padding-left: var(--space-xxxxl); }
  .padding-left-0     { padding-left: 0; }
  .padding-left-component { padding-left: var(--component-padding); }

  .padding-x-xxxxs { padding-left: var(--space-xxxxs); padding-right: var(--space-xxxxs); }
  .padding-x-xxxs  { padding-left: var(--space-xxxs); padding-right: var(--space-xxxs); }
  .padding-x-xxs   { padding-left: var(--space-xxs); padding-right: var(--space-xxs); }
  .padding-x-xs    { padding-left: var(--space-xs); padding-right: var(--space-xs); }
  .padding-x-sm    { padding-left: var(--space-sm); padding-right: var(--space-sm); }
  .padding-x-md    { padding-left: var(--space-md); padding-right: var(--space-md); }
  .padding-x-lg    { padding-left: var(--space-lg); padding-right: var(--space-lg); }
  .padding-x-xl    { padding-left: var(--space-xl); padding-right: var(--space-xl); }
  .padding-x-xxl   { padding-left: var(--space-xxl); padding-right: var(--space-xxl); }
  .padding-x-xxxl  { padding-left: var(--space-xxxl); padding-right: var(--space-xxxl); }
  .padding-x-xxxxl { padding-left: var(--space-xxxxl); padding-right: var(--space-xxxxl); }
  .padding-x-0     { padding-left: 0; padding-right: 0; }
  .padding-x-component { padding-left: var(--component-padding); padding-right: var(--component-padding); }

  .padding-y-xxxxs { padding-top: var(--space-xxxxs); padding-bottom: var(--space-xxxxs); }
  .padding-y-xxxs  { padding-top: var(--space-xxxs); padding-bottom: var(--space-xxxs); }
  .padding-y-xxs   { padding-top: var(--space-xxs); padding-bottom: var(--space-xxs); }
  .padding-y-xs    { padding-top: var(--space-xs); padding-bottom: var(--space-xs); }
  .padding-y-sm    { padding-top: var(--space-sm); padding-bottom: var(--space-sm); }
  .padding-y-md    { padding-top: var(--space-md); padding-bottom: var(--space-md); }
  .padding-y-lg    { padding-top: var(--space-lg); padding-bottom: var(--space-lg); }
  .padding-y-xl    { padding-top: var(--space-xl); padding-bottom: var(--space-xl); }
  .padding-y-xxl   { padding-top: var(--space-xxl); padding-bottom: var(--space-xxl); }
  .padding-y-xxxl  { padding-top: var(--space-xxxl); padding-bottom: var(--space-xxxl); }
  .padding-y-xxxxl { padding-top: var(--space-xxxxl); padding-bottom: var(--space-xxxxl); }
  .padding-y-0     { padding-top: 0; padding-bottom: 0; }
  .padding-y-component { padding-top: var(--component-padding); padding-bottom: var(--component-padding); }

  // --------------------------------

  // Vertical Align

  // --------------------------------

  .align-baseline { vertical-align: baseline; }
  .align-top      { vertical-align: top; }
  .align-middle   { vertical-align: middle; }
  .align-bottom   { vertical-align: bottom; }

  // --------------------------------

  // Typography

  // --------------------------------

  .truncate, .text-truncate { // truncate text if it exceeds its parent
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .text-replace { // replace text with bg img
    overflow: hidden;
    color: transparent;
    text-indent: 100%;
    white-space: nowrap;
  }

  .break-word {
    overflow-wrap: break-word;
    min-width: 0;
  }

  // --------------------------------

  // Font Size

  // --------------------------------

  .text-xs    { font-size: var(--text-xs, 0.694em); }
  .text-sm    { font-size: var(--text-sm, 0.833em); }
  .text-base  { font-size: var(--text-unit, 1em); }
  .text-md    { font-size: var(--text-md, 1.2em); }
  .text-lg    { font-size: var(--text-lg, 1.44em); }
  .text-xl    { font-size: var(--text-xl, 1.728em); }
  .text-xxl   { font-size: var(--text-xxl, 2.074em); }
  .text-xxxl  { font-size: var(--text-xxxl, 2.488em); }
  .text-xxxxl { font-size: var(--text-xxxxl, 2.985em); }

  .text-unit-rem, .text-unit-em, .text-unit-px {
    font-size: var(--text-unit);
  }

  .text-unit-rem { --text-unit: 1rem; }
  .text-unit-em  { --text-unit: 1em; }
  .text-unit-px  { --text-unit: 16px; }

  // --------------------------------

  // Text Transform

  // --------------------------------

  .text-uppercase  { text-transform: uppercase; }
  .text-capitalize { text-transform: capitalize; }

  // --------------------------------

  // Letter Spacing

  // --------------------------------

  .letter-spacing-xs { letter-spacing: -0.1em; }
  .letter-spacing-sm { letter-spacing: -0.05em; }
  .letter-spacing-md { letter-spacing: 0.05em; }
  .letter-spacing-lg { letter-spacing: 0.1em; }
  .letter-spacing-xl { letter-spacing: 0.2em; }

  // --------------------------------

  // Font Weight

  // --------------------------------

  .font-light    { font-weight: 300; }
  .font-normal   { font-weight: 400; }
  .font-medium   { font-weight: 500; }
  .font-semibold { font-weight: 600; }
  .font-bold, .text-bold { font-weight: 700; }

  // --------------------------------

  // Font Style

  // --------------------------------

  .font-italic { font-style: italic; }

  // --------------------------------

  // Font Smooth

  // --------------------------------

  .font-smooth {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  // --------------------------------

  // Font Family

  // --------------------------------

  .font-primary { font-family: var(--font-primary); }

  // --------------------------------

  // Text Align

  // --------------------------------

  .text-center  { text-align: center; }
  .text-left    { text-align: left; }
  .text-right   { text-align: right; }
  .text-justify { text-align: justify; }

  // --------------------------------

  // Text Decoration

  // --------------------------------

  .text-line-through    { text-decoration: line-through; }
  .text-underline       { text-decoration: underline; }
  .text-decoration-none { text-decoration: none; }

  // --------------------------------

  // Text Shadow

  // --------------------------------

  .text-shadow-xs { text-shadow: 0 1px 1px rgba(#000, 0.15); }
  .text-shadow-sm { text-shadow: 0 1px 2px rgba(#000, 0.25); }
  .text-shadow-md { text-shadow: 0 1px 2px rgba(#000, 0.1), 0 2px 4px rgba(#000, 0.2); }
  .text-shadow-lg { text-shadow: 0 1px 4px rgba(#000, 0.1), 0 2px 8px rgba(#000, 0.15), 0 4px 16px rgba(#000, 0.2); }
  .text-shadow-xl { text-shadow: 0 1px 4px rgba(#000, 0.1), 0 2px 8px rgba(#000, 0.15), 0 4px 16px rgba(#000, 0.2), 0 6px 24px rgba(#000, 0.25); }
  .text-shadow-none { text-shadow: none; }

  // --------------------------------

  // .text-component vertical spacing

  // --------------------------------

  .v-space-xxs { --text-vspace-multiplier: 0.25 !important; }
  .v-space-xs  { --text-vspace-multiplier: 0.5 !important; }
  .v-space-sm  { --text-vspace-multiplier: 0.75 !important; }
  .v-space-md  { --text-vspace-multiplier: 1.25 !important; }
  .v-space-lg  { --text-vspace-multiplier: 1.5 !important; }
  .v-space-xl  { --text-vspace-multiplier: 1.75 !important; }
  .v-space-xxl { --text-vspace-multiplier: 2 !important; }

  // --------------------------------

  // Line Height

  // --------------------------------

  .line-height-xs {
    --heading-line-height: 1;
    --body-line-height: 1.1;

    &:not(.text-component) {
      line-height: 1.1;
    }
  }

  .line-height-sm {
    --heading-line-height: 1.1;
    --body-line-height: 1.2;

    &:not(.text-component) {
      line-height: 1.2;
    }
  }

  .line-height-md {
    --heading-line-height: 1.15;
    --body-line-height: 1.4;

    &:not(.text-component) {
      line-height: 1.4;
    }
  }

  .line-height-lg {
    --heading-line-height: 1.22;
    --body-line-height: 1.58;

    &:not(.text-component) {
      line-height: 1.58;
    }
  }

  .line-height-xl {
    --heading-line-height: 1.3;
    --body-line-height: 1.72;

    &:not(.text-component) {
      line-height: 1.72;
    }
  }

  .line-height-body    { line-height: var(--body-line-height); }
  .line-height-heading { line-height: var(--heading-line-height); }
  .line-height-normal  { line-height: normal !important; }
  .line-height-1       { line-height: 1 !important; }

  // --------------------------------

  // White Space

  // --------------------------------

  .ws-nowrap, .text-nowrap { white-space: nowrap; }

  // --------------------------------

  // Cursor

  // --------------------------------

  .cursor-pointer { cursor: pointer; }
  .cursor-default { cursor: default; }

  // --------------------------------

  // Pointer Events

  // --------------------------------

  .pointer-events-auto { pointer-events: auto; }
  .pointer-events-none { pointer-events: none; }

  // --------------------------------

  // User Select

  // --------------------------------

  .user-select-none { user-select: none; }
  .user-select-all  { user-select: all; }

  // --------------------------------

  // Color

  // --------------------------------

  [class^="color-"], [class*=" color-"] { --color-o: 1; }

  .color-inherit { color: inherit; }

  .color-bg { color: alpha(var(--color-bg), var(--color-o, 1)); }
  .color-contrast-lower { color: alpha(var(--color-contrast-lower), var(--color-o, 1)); }
  .color-contrast-low { color: alpha(var(--color-contrast-low), var(--color-o, 1)); }
  .color-contrast-medium { color: alpha(var(--color-contrast-medium), var(--color-o, 1)); }
  .color-contrast-high { color: alpha(var(--color-contrast-high), var(--color-o, 1)); }
  .color-contrast-higher { color: alpha(var(--color-contrast-higher), var(--color-o, 1)); }

  .color-primary-darker { color: alpha(var(--color-primary-darker), var(--color-o, 1)); }
  .color-primary-dark { color: alpha(var(--color-primary-dark), var(--color-o, 1)); }
  .color-primary { color: alpha(var(--color-primary), var(--color-o, 1)); }
  .color-primary-light { color: alpha(var(--color-primary-light), var(--color-o, 1)); }
  .color-primary-lighter { color: alpha(var(--color-primary-lighter), var(--color-o, 1)); }

  .color-accent-darker { color: alpha(var(--color-accent-darker), var(--color-o, 1)); }
  .color-accent-dark { color: alpha(var(--color-accent-dark), var(--color-o, 1)); }
  .color-accent { color: alpha(var(--color-accent), var(--color-o, 1)); }
  .color-accent-light { color: alpha(var(--color-accent-light), var(--color-o, 1)); }
  .color-accent-lighter { color: alpha(var(--color-accent-lighter), var(--color-o, 1)); }

  .color-secondary-darker { color: alpha(var(--color-secondary-darker), var(--color-o, 1)); }
  .color-secondary-dark { color: alpha(var(--color-secondary-dark), var(--color-o, 1)); }
  .color-secondary { color: alpha(var(--color-secondary), var(--color-o, 1)); }
  .color-secondary-light { color: alpha(var(--color-secondary-light), var(--color-o, 1)); }
  .color-secondary-lighter { color: alpha(var(--color-secondary-lighter), var(--color-o, 1)); }

  .color-success-darker { color: alpha(var(--color-success-darker), var(--color-o, 1)); }
  .color-success-dark { color: alpha(var(--color-success-dark), var(--color-o, 1)); }
  .color-success { color: alpha(var(--color-success), var(--color-o, 1)); }
  .color-success-light { color: alpha(var(--color-success-light), var(--color-o, 1)); }
  .color-success-lighter { color: alpha(var(--color-success-lighter), var(--color-o, 1)); }

  .color-warning-darker { color: alpha(var(--color-warning-darker), var(--color-o, 1)); }
  .color-warning-dark { color: alpha(var(--color-warning-dark), var(--color-o, 1)); }
  .color-warning { color: alpha(var(--color-warning), var(--color-o, 1)); }
  .color-warning-light { color: alpha(var(--color-warning-light), var(--color-o, 1)); }
  .color-warning-lighter { color: alpha(var(--color-warning-lighter), var(--color-o, 1)); }

  .color-error-darker { color: alpha(var(--color-error-darker), var(--color-o, 1)); }
  .color-error-dark { color: alpha(var(--color-error-dark), var(--color-o, 1)); }
  .color-error { color: alpha(var(--color-error), var(--color-o, 1)); }
  .color-error-light { color: alpha(var(--color-error-light), var(--color-o, 1)); }
  .color-error-lighter { color: alpha(var(--color-error-lighter), var(--color-o, 1)); }

  .color-white { color: alpha(var(--color-white), var(--color-o, 1)); }
  .color-black { color: alpha(var(--color-black), var(--color-o, 1)); }

  @supports (--css: variables) {
    .color-opacity-0    { --color-o: 0; }
    .color-opacity-10\% { --color-o: 0.1; }
    .color-opacity-20\% { --color-o: 0.2; }
    .color-opacity-30\% { --color-o: 0.3; }
    .color-opacity-40\% { --color-o: 0.4; }
    .color-opacity-50\% { --color-o: 0.5; }
    .color-opacity-60\% { --color-o: 0.6; }
    .color-opacity-70\% { --color-o: 0.7; }
    .color-opacity-80\% { --color-o: 0.8; }
    .color-opacity-90\% { --color-o: 0.9; }
  }

  // --------------------------------

  // Gradients

  // --------------------------------

  @supports (--css: variables) {
    [class^="color-gradient"], [class*=" color-gradient"] {
      color: transparent !important;
      background-clip: text;
    }
  }

  // --------------------------------

  // Width

  // --------------------------------

  .width-xxxxs { width: var(--size-xxxxs, 0.25rem); }
  .width-xxxs  { width: var(--size-xxxs, 0.5rem); }
  .width-xxs   { width: var(--size-xxs, 0.75rem); }
  .width-xs    { width: var(--size-xs, 1rem); }
  .width-sm    { width: var(--size-sm, 1.5rem); }
  .width-md    { width: var(--size-md, 2rem); }
  .width-lg    { width: var(--size-lg, 3rem); }
  .width-xl    { width: var(--size-xl, 4rem); }
  .width-xxl   { width: var(--size-xxl, 6rem); }
  .width-xxxl  { width: var(--size-xxxl, 8rem); }
  .width-xxxxl { width: var(--size-xxxxl, 16rem); }
  .width-0     { width: 0; }
  .width-10\%  { width: 10%; }
  .width-20\%  { width: 20%; }
  .width-25\%  { width: 25%; }
  .width-30\%  { width: 30%; }
  .width-33\%  { width: calc(100% / 3); }
  .width-40\%  { width: 40%; }
  .width-50\%  { width: 50%; }
  .width-60\%  { width: 60%; }
  .width-66\%  { width: calc(100% / 1.5); }
  .width-70\%  { width: 70%; }
  .width-75\%  { width: 75%; }
  .width-80\%  { width: 80%; }
  .width-90\%  { width: 90%; }
  .width-100\% { width: 100%; }
  .width-100vw { width: 100vw; }
  .width-auto  { width: auto; }

  // --------------------------------

  // Height

  // --------------------------------

  .height-xxxxs { height: var(--size-xxxxs, 0.25rem); }
  .height-xxxs  { height: var(--size-xxxs, 0.5rem); }
  .height-xxs   { height: var(--size-xxs, 0.75rem); }
  .height-xs    { height: var(--size-xs, 1rem); }
  .height-sm    { height: var(--size-sm, 1.5rem); }
  .height-md    { height: var(--size-md, 2rem); }
  .height-lg    { height: var(--size-lg, 3rem); }
  .height-xl    { height: var(--size-xl, 4rem); }
  .height-xxl   { height: var(--size-xxl, 6rem); }
  .height-xxxl  { height: var(--size-xxxl, 8rem); }
  .height-xxxxl { height: var(--size-xxxxl, 16rem); }
  .height-0     { height: 0; }
  .height-10\%  { height: 10%; }
  .height-20\%  { height: 20%; }
  .height-25\%  { height: 25%; }
  .height-30\%  { height: 30%; }
  .height-33\%  { height: calc(100% / 3); }
  .height-40\%  { height: 40%; }
  .height-50\%  { height: 50%; }
  .height-60\%  { height: 60%; }
  .height-66\%  { height: calc(100% / 1.5); }
  .height-70\%  { height: 70%; }
  .height-75\%  { height: 75%; }
  .height-80\%  { height: 80%; }
  .height-90\%  { height: 90%; }
  .height-100\% { height: 100%; }
  .height-100vh { height: 100vh; }
  .height-auto  { height: auto; }

  // --------------------------------

  // Min-Width

  // --------------------------------

  .min-width-0     { min-width: 0; }
  .min-width-25\%  { min-width: 25%; }
  .min-width-33\%  { min-width: calc(100% / 3); }
  .min-width-50\%  { min-width: 50%; }
  .min-width-66\%  { min-width: calc(100% / 1.5); }
  .min-width-75\%  { min-width: 75%; }
  .min-width-100\% { min-width: 100%; }
  .min-width-100vw { min-width: 100vw; }

  // --------------------------------

  // Min-Height

  // --------------------------------

  .min-height-100\% { min-height: 100%; }
  .min-height-100vh { min-height: 100vh; }

  // --------------------------------

  // Max-Width

  // --------------------------------

  :root {
    --max-width-xxxxs: 20rem;  // ~320px
    --max-width-xxxs:  26rem;  // ~416px
    --max-width-xxs:   32rem;  // ~512px
    --max-width-xs:    38rem;  // ~608px
    --max-width-sm:    48rem;  // ~768px
    --max-width-md:    64rem;  // ~1024px
    --max-width-lg:    80rem;  // ~1280px
    --max-width-xl:    90rem;  // ~1440px
    --max-width-xxl:   100rem; // ~1600px
    --max-width-xxxl:  120rem; // ~1920px
    --max-width-xxxxl: 150rem; // ~2400px
  }

  .max-width-xxxxs { max-width: var(--max-width-xxxxs); }
  .max-width-xxxs  { max-width: var(--max-width-xxxs); }
  .max-width-xxs   { max-width: var(--max-width-xxs); }
  .max-width-xs    { max-width: var(--max-width-xs); }
  .max-width-sm    { max-width: var(--max-width-sm); }
  .max-width-md    { max-width: var(--max-width-md); }
  .max-width-lg    { max-width: var(--max-width-lg); }
  .max-width-xl    { max-width: var(--max-width-xl); }
  .max-width-xxl   { max-width: var(--max-width-xxl); }
  .max-width-xxxl  { max-width: var(--max-width-xxxl); }
  .max-width-xxxxl { max-width: var(--max-width-xxxxl); }
  .max-width-100\% { max-width: 100%; }

  // alt approach - max-width is equal to current breakpoint
  $breakpointsNr: length($breakpoints);
  @each $breakpoint, $value in $breakpoints {
    $i: index($breakpoints, $breakpoint $value);
    @if $i == 1 {
      [class^="max-width-adaptive"], [class*=" max-width-adaptive"] {
        max-width: map-get($map: $breakpoints, $key: #{$breakpoint});
      }
    } @else {
      $classList : '';
      @each $subBreakpoint, $subValue in $breakpoints {
        $j: index($breakpoints, $subBreakpoint $subValue);
        @if $j == $i {
          $classList: '.max-width-adaptive-#{$subBreakpoint}';
        } @else if $j > $i {
          $classList: $classList+', .max-width-adaptive-#{$subBreakpoint}';
        }
      }
      @if $i < $breakpointsNr {
        $classList: $classList+', .max-width-adaptive';
      }
      @include breakpoint(#{$breakpoint}) {
        #{$classList} {
          max-width: map-get($map: $breakpoints, $key: #{$breakpoint});
        }
      }
    }
  }

  // --------------------------------

  // Max-Height

  // --------------------------------

  .max-height-100\% { max-height: 100%; }
  .max-height-100vh { max-height: 100vh; }

  // --------------------------------

  // Position

  // --------------------------------

  .position-relative { position: relative; }
  .position-absolute { position: absolute; }
  .position-fixed    { position: fixed; }
  .position-sticky   { position: sticky; }

  .inset-0     { top: 0; right: 0; bottom: 0; left: 0; }

  .top-0     { top: 0; }
  .top-50\%  { top: 50%; }
  .top-xxxxs { top: var(--space-xxxxs); }
  .top-xxxs  { top: var(--space-xxxs); }
  .top-xxs   { top: var(--space-xxs); }
  .top-xs    { top: var(--space-xs); }
  .top-sm    { top: var(--space-sm); }
  .top-md    { top: var(--space-md); }
  .top-lg    { top: var(--space-lg); }
  .top-xl    { top: var(--space-xl); }
  .top-xxl   { top: var(--space-xxl); }
  .top-xxxl  { top: var(--space-xxxl); }
  .top-xxxxl { top: var(--space-xxxxl); }

  .bottom-0     { bottom: 0; }
  .bottom-50\%  { bottom: 50%; }
  .bottom-xxxxs { bottom: var(--space-xxxxs); }
  .bottom-xxxs  { bottom: var(--space-xxxs); }
  .bottom-xxs   { bottom: var(--space-xxs); }
  .bottom-xs    { bottom: var(--space-xs); }
  .bottom-sm    { bottom: var(--space-sm); }
  .bottom-md    { bottom: var(--space-md); }
  .bottom-lg    { bottom: var(--space-lg); }
  .bottom-xl    { bottom: var(--space-xl); }
  .bottom-xxl   { bottom: var(--space-xxl); }
  .bottom-xxxl  { bottom: var(--space-xxxl); }
  .bottom-xxxxl { bottom: var(--space-xxxxl); }

  .right-0     { right: 0; }
  .right-50\%  { right: 50%; }
  .right-xxxxs { right: var(--space-xxxxs); }
  .right-xxxs  { right: var(--space-xxxs); }
  .right-xxs   { right: var(--space-xxs); }
  .right-xs    { right: var(--space-xs); }
  .right-sm    { right: var(--space-sm); }
  .right-md    { right: var(--space-md); }
  .right-lg    { right: var(--space-lg); }
  .right-xl    { right: var(--space-xl); }
  .right-xxl   { right: var(--space-xxl); }
  .right-xxxl  { right: var(--space-xxxl); }
  .right-xxxxl { right: var(--space-xxxxl); }

  .left-0     { left: 0; }
  .left-50\%  { left: 50%; }
  .left-xxxxs { left: var(--space-xxxxs); }
  .left-xxxs  { left: var(--space-xxxs); }
  .left-xxs   { left: var(--space-xxs); }
  .left-xs    { left: var(--space-xs); }
  .left-sm    { left: var(--space-sm); }
  .left-md    { left: var(--space-md); }
  .left-lg    { left: var(--space-lg); }
  .left-xl    { left: var(--space-xl); }
  .left-xxl   { left: var(--space-xxl); }
  .left-xxxl  { left: var(--space-xxxl); }
  .left-xxxxl { left: var(--space-xxxxl); }

  // --------------------------------

  // Z-Index

  // --------------------------------

  .z-index-header { z-index: var(--zindex-header); }
  .z-index-popover { z-index: var(--zindex-popover); }
  .z-index-fixed-element { z-index: var(--zindex-fixed-element); }
  .z-index-overlay { z-index: var(--zindex-overlay); }

  .zindex-1, .z-index-1 { z-index: 1; }
  .zindex-2, .z-index-2 { z-index: 2; }
  .zindex-3, .z-index-3 { z-index: 3; }

  // --------------------------------

  // Overflow

  // --------------------------------

  .overflow-hidden { overflow: hidden; }
  .overflow-auto   { overflow: auto; }
  .momentum-scrolling { -webkit-overflow-scrolling: touch; }

  // overscroll-behavior
  .overscroll-contain { overscroll-behavior: contain; }

  // --------------------------------

  // Scroll Behavior

  // --------------------------------

  .scroll-smooth { scroll-behavior: smooth; }

  .scroll-padding-xxxxs { scroll-padding: var(--space-xxxxs); }
  .scroll-padding-xxxs  { scroll-padding: var(--space-xxxs); }
  .scroll-padding-xxs   { scroll-padding: var(--space-xxs); }
  .scroll-padding-xs    { scroll-padding: var(--space-xs); }
  .scroll-padding-sm    { scroll-padding: var(--space-sm); }
  .scroll-padding-md    { scroll-padding: var(--space-md); }
  .scroll-padding-lg    { scroll-padding: var(--space-lg); }
  .scroll-padding-xl    { scroll-padding: var(--space-xl); }
  .scroll-padding-xxl   { scroll-padding: var(--space-xxl); }
  .scroll-padding-xxxl  { scroll-padding: var(--space-xxxl); }
  .scroll-padding-xxxxl { scroll-padding: var(--space-xxxxl); }


  // --------------------------------

  // Opacity

  // --------------------------------

  .opacity-0    { opacity: 0; }
  .opacity-10\% { opacity: 0.1; }
  .opacity-20\% { opacity: 0.2; }
  .opacity-30\% { opacity: 0.3; }
  .opacity-40\% { opacity: 0.4; }
  .opacity-50\% { opacity: 0.5; }
  .opacity-60\% { opacity: 0.6; }
  .opacity-70\% { opacity: 0.7; }
  .opacity-80\% { opacity: 0.8; }
  .opacity-90\% { opacity: 0.9; }

  // --------------------------------

  // Media Wrapper - make iFrame responsive + prevent img jump when page loads
  // ⚠️ deprecated - replace with aspect ratio

  // --------------------------------

  $media-wrapper-aspect-ratios: (
    (4 3),
    (1 1)
  ) !default;

  .media-wrapper {
    position: relative;
    height: 0;
    padding-bottom: 56.25%; // 16:9 aspect ratio

    > * {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      &:not(iframe) {
        object-fit: cover;
      }
    }
  }

  @each $aspect-ratio in $media-wrapper-aspect-ratios {
    $aspect-ratio-x: nth($aspect-ratio, 1);
    $aspect-ratio-y: nth($aspect-ratio, 2);

    .media-wrapper--#{$aspect-ratio-x}\:#{$aspect-ratio-y} {
      padding-bottom: calc((#{$aspect-ratio-y} / #{$aspect-ratio-x}) * 100%);
    }
  }

  // --------------------------------

  // Float

  // --------------------------------

  .float-left  { float: left; }
  .float-right { float: right; }

  .clearfix::after {
    content: "";
    display: table;
    clear: both;
  }

  // --------------------------------

  // Border

  // --------------------------------

  [class^="border-"], [class*=" border-"] { --border-o: 1; }

  .border { border: 1px solid alpha(var(--color-contrast-low), var(--border-o, 1)); }
  .border-top { border-top: 1px solid alpha(var(--color-contrast-low), var(--border-o, 1)); }
  .border-bottom { border-bottom: 1px solid alpha(var(--color-contrast-low), var(--border-o, 1)); }
  .border-left { border-left: 1px solid alpha(var(--color-contrast-low), var(--border-o, 1)); }
  .border-right { border-right: 1px solid alpha(var(--color-contrast-low), var(--border-o, 1)); }
  .border-2 { border-width: 2px; }
  .border-3 { border-width: 3px; }
  .border-4 { border-width: 4px; }

  .border-bg { border-color: alpha(var(--color-bg), var(--border-o, 1)); }
  .border-contrast-lower { border-color: alpha(var(--color-contrast-lower), var(--border-o, 1)); }
  .border-contrast-low { border-color: alpha(var(--color-contrast-low), var(--border-o, 1)); }
  .border-contrast-medium { border-color: alpha(var(--color-contrast-medium), var(--border-o, 1)); }
  .border-contrast-high { border-color: alpha(var(--color-contrast-high), var(--border-o, 1)); }
  .border-contrast-higher { border-color: alpha(var(--color-contrast-higher), var(--border-o, 1)); }

  .border-primary-darker { border-color: alpha(var(--color-primary-darker), var(--border-o, 1)); }
  .border-primary-dark { border-color: alpha(var(--color-primary-dark), var(--border-o, 1)); }
  .border-primary { border-color: alpha(var(--color-primary), var(--border-o, 1)); }
  .border-primary-light { border-color: alpha(var(--color-primary-light), var(--border-o, 1)); }
  .border-primary-lighter { border-color: alpha(var(--color-primary-lighter), var(--border-o, 1)); }

  .border-accent-darker { border-color: alpha(var(--color-accent-darker), var(--border-o, 1)); }
  .border-accent-dark { border-color: alpha(var(--color-accent-dark), var(--border-o, 1)); }
  .border-accent { border-color: alpha(var(--color-accent), var(--border-o, 1)); }
  .border-accent-light { border-color: alpha(var(--color-accent-light), var(--border-o, 1)); }
  .border-accent-lighter { border-color: alpha(var(--color-accent-lighter), var(--border-o, 1)); }

  .border-success-darker { border-color: alpha(var(--color-success-darker), var(--border-o, 1)); }
  .border-success-dark { border-color: alpha(var(--color-success-dark), var(--border-o, 1)); }
  .border-success { border-color: alpha(var(--color-success), var(--border-o, 1)); }
  .border-success-light { border-color: alpha(var(--color-success-light), var(--border-o, 1)); }
  .border-success-lighter { border-color: alpha(var(--color-success-lighter), var(--border-o, 1)); }

  .border-warning-darker { border-color: alpha(var(--color-warning-darker), var(--border-o, 1)); }
  .border-warning-dark { border-color: alpha(var(--color-warning-dark), var(--border-o, 1)); }
  .border-warning { border-color: alpha(var(--color-warning), var(--border-o, 1)); }
  .border-warning-light { border-color: alpha(var(--color-warning-light), var(--border-o, 1)); }
  .border-warning-lighter { border-color: alpha(var(--color-warning-lighter), var(--border-o, 1)); }

  .border-error-darker { border-color: alpha(var(--color-error-darker), var(--border-o, 1)); }
  .border-error-dark { border-color: alpha(var(--color-error-dark), var(--border-o, 1)); }
  .border-error { border-color: alpha(var(--color-error), var(--border-o, 1)); }
  .border-error-light { border-color: alpha(var(--color-error-light), var(--border-o, 1)); }
  .border-error-lighter { border-color: alpha(var(--color-error-lighter), var(--border-o, 1)); }

  .border-white { border-color: alpha(var(--color-white), var(--border-o, 1)); }
  .border-black { border-color: alpha(var(--color-black), var(--border-o, 1)); }

  @supports (--css: variables) {
    .border-opacity-0    { --border-o: 0; }
    .border-opacity-10\% { --border-o: 0.1; }
    .border-opacity-20\% { --border-o: 0.2; }
    .border-opacity-30\% { --border-o: 0.3; }
    .border-opacity-40\% { --border-o: 0.4; }
    .border-opacity-50\% { --border-o: 0.5; }
    .border-opacity-60\% { --border-o: 0.6; }
    .border-opacity-70\% { --border-o: 0.7; }
    .border-opacity-80\% { --border-o: 0.8; }
    .border-opacity-90\% { --border-o: 0.9; }
  }

  // --------------------------------

  // Border Radius

  // --------------------------------

  .radius-sm { border-radius: var(--radius-sm); }
  .radius-md { border-radius: var(--radius-md); }
  .radius-lg { border-radius: var(--radius-lg); }
  .radius-50\% { border-radius: 50%; }
  .radius-full { border-radius: 50em; }
  .radius-0 { border-radius: 0; }
  .radius-top-left-0 { border-top-left-radius: 0; }
  .radius-top-right-0 { border-top-right-radius: 0; }
  .radius-bottom-right-0 { border-bottom-right-radius: 0; }
  .radius-bottom-left-0 { border-bottom-left-radius: 0; }

  // --------------------------------

  // Box-Shadow

  // --------------------------------

  .shadow-xs { box-shadow: var(--shadow-xs); }
  .shadow-sm { box-shadow: var(--shadow-sm); }
  .shadow-md { box-shadow: var(--shadow-md); }
  .shadow-lg { box-shadow: var(--shadow-lg); }
  .shadow-xl { box-shadow: var(--shadow-xl); }
  .shadow-none { box-shadow: none; }

  // --------------------------------

  // Background

  // --------------------------------

  .bg, [class^="bg-"], [class*=" bg-"] { --bg-o: 1; }

  .bg-transparent { background-color: transparent; }
  .bg-inherit { background-color: inherit; }

  .bg { background-color: alpha(var(--color-bg), var(--bg-o)); }
  .bg-contrast-lower { background-color: alpha(var(--color-contrast-lower), var(--bg-o, 1)); }
  .bg-contrast-low { background-color: alpha(var(--color-contrast-low), var(--bg-o, 1)); }
  .bg-contrast-medium { background-color: alpha(var(--color-contrast-medium), var(--bg-o, 1)); }
  .bg-contrast-high { background-color: alpha(var(--color-contrast-high), var(--bg-o, 1)); }
  .bg-contrast-higher { background-color: alpha(var(--color-contrast-higher), var(--bg-o, 1)); }

  .bg-primary-darker { background-color: alpha(var(--color-primary-darker), var(--bg-o, 1)); }
  .bg-primary-dark { background-color: alpha(var(--color-primary-dark), var(--bg-o, 1)); }
  .bg-primary { background-color: alpha(var(--color-primary), var(--bg-o, 1)); }
  .bg-primary-light { background-color: alpha(var(--color-primary-light), var(--bg-o, 1)); }
  .bg-primary-lighter { background-color: alpha(var(--color-primary-lighter), var(--bg-o, 1)); }

  .bg-accent-darker { background-color: alpha(var(--color-accent-darker), var(--bg-o, 1)); }
  .bg-accent-dark { background-color: alpha(var(--color-accent-dark), var(--bg-o, 1)); }
  .bg-accent { background-color: alpha(var(--color-accent), var(--bg-o, 1)); }
  .bg-accent-light { background-color: alpha(var(--color-accent-light), var(--bg-o, 1)); }
  .bg-accent-lighter { background-color: alpha(var(--color-accent-lighter), var(--bg-o, 1)); }

  .bg-success-darker { background-color: alpha(var(--color-success-darker), var(--bg-o, 1)); }
  .bg-success-dark { background-color: alpha(var(--color-success-dark), var(--bg-o, 1)); }
  .bg-success { background-color: alpha(var(--color-success), var(--bg-o, 1)); }
  .bg-success-light { background-color: alpha(var(--color-success-light), var(--bg-o, 1)); }
  .bg-success-lighter { background-color: alpha(var(--color-success-lighter), var(--bg-o, 1)); }

  .bg-warning-darker { background-color: alpha(var(--color-warning-darker), var(--bg-o, 1)); }
  .bg-warning-dark { background-color: alpha(var(--color-warning-dark), var(--bg-o, 1)); }
  .bg-warning { background-color: alpha(var(--color-warning), var(--bg-o, 1)); }
  .bg-warning-light { background-color: alpha(var(--color-warning-light), var(--bg-o, 1)); }
  .bg-warning-lighter { background-color: alpha(var(--color-warning-lighter), var(--bg-o, 1)); }

  .bg-error-darker { background-color: alpha(var(--color-error-darker), var(--bg-o, 1)); }
  .bg-error-dark { background-color: alpha(var(--color-error-dark), var(--bg-o, 1)); }
  .bg-error { background-color: alpha(var(--color-error), var(--bg-o, 1)); }
  .bg-error-light { background-color: alpha(var(--color-error-light), var(--bg-o, 1)); }
  .bg-error-lighter { background-color: alpha(var(--color-error-lighter), var(--bg-o, 1)); }

  .bg-white { background-color: alpha(var(--color-white), var(--bg-o, 1)); }
  .bg-black { background-color: alpha(var(--color-black), var(--bg-o, 1)); }

  @supports (--css: variables) {
    .bg-opacity-0    { --bg-o: 0; }
    .bg-opacity-10\% { --bg-o: 0.1; }
    .bg-opacity-20\% { --bg-o: 0.2; }
    .bg-opacity-30\% { --bg-o: 0.3; }
    .bg-opacity-40\% { --bg-o: 0.4; }
    .bg-opacity-50\% { --bg-o: 0.5; }
    .bg-opacity-60\% { --bg-o: 0.6; }
    .bg-opacity-70\% { --bg-o: 0.7; }
    .bg-opacity-80\% { --bg-o: 0.8; }
    .bg-opacity-90\% { --bg-o: 0.9; }
  }

  .bg-cover { background-size: cover; }
  .bg-center { background-position: center; }
  .bg-no-repeat { background-repeat: no-repeat; }

  // --------------------------------

  // Backdrop Filter

  // --------------------------------

  .backdrop-blur-10 { backdrop-filter: blur(10px); }
  .backdrop-blur-20 { backdrop-filter: blur(20px); }

  // --------------------------------

  // Mix-Blend Mode

  // --------------------------------

  .isolate { isolation: isolate; }
  .blend-multiply { mix-blend-mode: multiply; }
  .blend-overlay { mix-blend-mode: overlay; }
  .blend-difference { mix-blend-mode: difference; }

  // --------------------------------

  // Object-Fit

  // --------------------------------

  .object-contain { object-fit: contain; }
  .object-cover   { object-fit: cover; }

  // --------------------------------

  // Perspective

  // --------------------------------

  .perspective-xs { perspective: 250px; }
  .perspective-sm { perspective: 500px; }
  .perspective-md { perspective: 1000px; }
  .perspective-lg { perspective: 1500px; }
  .perspective-xl { perspective: 3000px; }

  // --------------------------------

  // Transform

  // --------------------------------

  @supports (--css: variables) {
    [class^="flip"], [class*=" flip"],
    [class^="-rotate"], [class*=" -rotate"],
    [class^="rotate"], [class*=" rotate"],
    [class^="-translate"], [class*=" -translate"],
    [class^="translate"], [class*=" translate"],
    [class^="-scale"], [class*=" -scale"],
    [class^="scale"], [class*=" scale"],
    [class^="-skew"], [class*=" -skew"]
    [class^="skew"], [class*=" skew"] {
      --translate: 0;
      --rotate: 0;
      --skew: 0;
      --scale: 1;

      transform: translate3d(var(--translate-x, var(--translate)), var(--translate-y, var(--translate)), var(--translate-z, 0)) rotateX(var(--rotate-x, 0)) rotateY(var(--rotate-y, 0)) rotateZ(var(--rotate-z, var(--rotate))) skewX(var(--skew-x, var(--skew))) skewY(var(--skew-y, 0)) scaleX(var(--scale-x, var(--scale))) scaleY(var(--scale-y, var(--scale)));
    }

    .flip   { --scale: -1; }
    .flip-x { --scale-x: -1; }
    .flip-y { --scale-y: -1; }

    .rotate-90  { --rotate: 90deg; }
    .rotate-180 { --rotate: 180deg; }
    .rotate-270 { --rotate: 270deg; }

    .-translate-50\%   { --translate: -50%; }
    .-translate-x-50\% { --translate-x: -50%; }
    .-translate-y-50\% { --translate-y: -50%; }

    .translate-50\%   { --translate: 50%; }
    .translate-x-50\% { --translate-x: 50%; }
    .translate-y-50\% { --translate-y: 50%; }
  }

  // --------------------------------

  // Transform Origin

  // --------------------------------

  .origin-center { transform-origin: center; }
  .origin-top { transform-origin: center top; }
  .origin-right { transform-origin: right center; }
  .origin-bottom { transform-origin: center bottom; }
  .origin-left { transform-origin: left center; }
  .origin-top-left { transform-origin: left top; }
  .origin-top-right { transform-origin: right top; }
  .origin-bottom-left { transform-origin: left bottom; }
  .origin-bottom-right { transform-origin: right bottom; }

  // --------------------------------

  // SVG

  // --------------------------------

  .fill-current { fill: currentColor; }

  .stroke-current { stroke: currentColor; }

  .stroke-1 { stroke-width: 1px; }
  .stroke-2 { stroke-width: 2px; }
  .stroke-3 { stroke-width: 3px; }
  .stroke-4 { stroke-width: 4px; }

  // --------------------------------

  // Visibility

  // --------------------------------

  .visible { visibility: visible; }
  .hidden  { visibility: hidden; }

  // --------------------------------

  // Responsive Variations

  // --------------------------------

  @each $breakpoint, $value in $breakpoints {
    @include breakpoint(#{$breakpoint}) {
      // flexbox
      .flex\@#{$breakpoint} { display: flex; }
      .inline-flex\@#{$breakpoint} { display: inline-flex; }
      .flex-wrap\@#{$breakpoint} { flex-wrap: wrap; }
      .flex-column\@#{$breakpoint} { flex-direction: column; }
      .flex-column-reverse\@#{$breakpoint} { flex-direction: column-reverse; }
      .flex-row\@#{$breakpoint} { flex-direction: row; }
      .flex-row-reverse\@#{$breakpoint} { flex-direction: row-reverse; }
      .flex-center\@#{$breakpoint} { justify-content: center; align-items: center; }

      .flex-grow\@#{$breakpoint} { flex-grow: 1; }
      .flex-grow-0\@#{$breakpoint} { flex-grow: 0; }
      .flex-shrink\@#{$breakpoint} { flex-shrink: 1; }
      .flex-shrink-0\@#{$breakpoint} { flex-shrink: 0; }
      .flex-basis-0\@#{$breakpoint} { flex-basis: 0; }

      // justify-content
      .justify-start\@#{$breakpoint} { justify-content: flex-start; }
      .justify-end\@#{$breakpoint} { justify-content: flex-end; }
      .justify-center\@#{$breakpoint} { justify-content: center; }
      .justify-between\@#{$breakpoint} { justify-content: space-between; }

      // align-items
      .items-center\@#{$breakpoint} { align-items: center; }
      .items-start\@#{$breakpoint} { align-items: flex-start; }
      .items-end\@#{$breakpoint} { align-items: flex-end; }
      .items-baseline\@#{$breakpoint} { align-items: baseline; }

      // order
      .order-1\@#{$breakpoint}  { order: 1; }
      .order-2\@#{$breakpoint}  { order: 2; }
      .order-3\@#{$breakpoint}  { order: 3; }

      // display
      .block\@#{$breakpoint} { display: block; }
      .inline-block\@#{$breakpoint} { display: inline-block; }
      .inline\@#{$breakpoint} { display: inline; }

      // margin
      @supports (--css: variables) {
        .margin-xxxxs\@#{$breakpoint} { margin: var(--space-xxxxs); }
        .margin-xxxs\@#{$breakpoint}  { margin: var(--space-xxxs); }
        .margin-xxs\@#{$breakpoint}   { margin: var(--space-xxs); }
        .margin-xs\@#{$breakpoint}    { margin: var(--space-xs); }
        .margin-sm\@#{$breakpoint}    { margin: var(--space-sm); }
        .margin-md\@#{$breakpoint}    { margin: var(--space-md); }
        .margin-lg\@#{$breakpoint}    { margin: var(--space-lg); }
        .margin-xl\@#{$breakpoint}    { margin: var(--space-xl); }
        .margin-xxl\@#{$breakpoint}   { margin: var(--space-xxl); }
        .margin-xxxl\@#{$breakpoint}  { margin: var(--space-xxxl); }
        .margin-xxxxl\@#{$breakpoint} { margin: var(--space-xxxxl); }
        .margin-auto\@#{$breakpoint}  { margin: auto; }
        .margin-0\@#{$breakpoint}     { margin: 0; }

        .margin-top-xxxxs\@#{$breakpoint} { margin-top: var(--space-xxxxs); }
        .margin-top-xxxs\@#{$breakpoint}  { margin-top: var(--space-xxxs); }
        .margin-top-xxs\@#{$breakpoint}   { margin-top: var(--space-xxs); }
        .margin-top-xs\@#{$breakpoint}    { margin-top: var(--space-xs); }
        .margin-top-sm\@#{$breakpoint}    { margin-top: var(--space-sm); }
        .margin-top-md\@#{$breakpoint}    { margin-top: var(--space-md); }
        .margin-top-lg\@#{$breakpoint}    { margin-top: var(--space-lg); }
        .margin-top-xl\@#{$breakpoint}    { margin-top: var(--space-xl); }
        .margin-top-xxl\@#{$breakpoint}   { margin-top: var(--space-xxl); }
        .margin-top-xxxl\@#{$breakpoint}  { margin-top: var(--space-xxxl); }
        .margin-top-xxxxl\@#{$breakpoint} { margin-top: var(--space-xxxxl); }
        .margin-top-auto\@#{$breakpoint}  { margin-top: auto; }
        .margin-top-0\@#{$breakpoint}     { margin-top: 0; }

        .margin-bottom-xxxxs\@#{$breakpoint} { margin-bottom: var(--space-xxxxs); }
        .margin-bottom-xxxs\@#{$breakpoint}  { margin-bottom: var(--space-xxxs); }
        .margin-bottom-xxs\@#{$breakpoint}   { margin-bottom: var(--space-xxs); }
        .margin-bottom-xs\@#{$breakpoint}    { margin-bottom: var(--space-xs); }
        .margin-bottom-sm\@#{$breakpoint}    { margin-bottom: var(--space-sm); }
        .margin-bottom-md\@#{$breakpoint}    { margin-bottom: var(--space-md); }
        .margin-bottom-lg\@#{$breakpoint}    { margin-bottom: var(--space-lg); }
        .margin-bottom-xl\@#{$breakpoint}    { margin-bottom: var(--space-xl); }
        .margin-bottom-xxl\@#{$breakpoint}   { margin-bottom: var(--space-xxl); }
        .margin-bottom-xxxl\@#{$breakpoint}  { margin-bottom: var(--space-xxxl); }
        .margin-bottom-xxxxl\@#{$breakpoint} { margin-bottom: var(--space-xxxxl); }
        .margin-bottom-auto\@#{$breakpoint}  { margin-bottom: auto; }
        .margin-bottom-0\@#{$breakpoint}     { margin-bottom: 0; }

        .margin-right-xxxxs\@#{$breakpoint} { margin-right: var(--space-xxxxs); }
        .margin-right-xxxs\@#{$breakpoint}  { margin-right: var(--space-xxxs); }
        .margin-right-xxs\@#{$breakpoint}   { margin-right: var(--space-xxs); }
        .margin-right-xs\@#{$breakpoint}    { margin-right: var(--space-xs); }
        .margin-right-sm\@#{$breakpoint}    { margin-right: var(--space-sm); }
        .margin-right-md\@#{$breakpoint}    { margin-right: var(--space-md); }
        .margin-right-lg\@#{$breakpoint}    { margin-right: var(--space-lg); }
        .margin-right-xl\@#{$breakpoint}    { margin-right: var(--space-xl); }
        .margin-right-xxl\@#{$breakpoint}   { margin-right: var(--space-xxl); }
        .margin-right-xxxl\@#{$breakpoint}  { margin-right: var(--space-xxxl); }
        .margin-right-xxxxl\@#{$breakpoint} { margin-right: var(--space-xxxxl); }
        .margin-right-auto\@#{$breakpoint}  { margin-right: auto; }
        .margin-right-0\@#{$breakpoint}     { margin-right: 0; }

        .margin-left-xxxxs\@#{$breakpoint} { margin-left: var(--space-xxxxs); }
        .margin-left-xxxs\@#{$breakpoint}  { margin-left: var(--space-xxxs); }
        .margin-left-xxs\@#{$breakpoint}   { margin-left: var(--space-xxs); }
        .margin-left-xs\@#{$breakpoint}    { margin-left: var(--space-xs); }
        .margin-left-sm\@#{$breakpoint}    { margin-left: var(--space-sm); }
        .margin-left-md\@#{$breakpoint}    { margin-left: var(--space-md); }
        .margin-left-lg\@#{$breakpoint}    { margin-left: var(--space-lg); }
        .margin-left-xl\@#{$breakpoint}    { margin-left: var(--space-xl); }
        .margin-left-xxl\@#{$breakpoint}   { margin-left: var(--space-xxl); }
        .margin-left-xxxl\@#{$breakpoint}  { margin-left: var(--space-xxxl); }
        .margin-left-xxxxl\@#{$breakpoint} { margin-left: var(--space-xxxxl); }
        .margin-left-auto\@#{$breakpoint}  { margin-left: auto; }
        .margin-left-0\@#{$breakpoint}     { margin-left: 0; }

        .margin-x-xxxxs\@#{$breakpoint} { margin-left: var(--space-xxxxs); margin-right: var(--space-xxxxs); }
        .margin-x-xxxs\@#{$breakpoint}  { margin-left: var(--space-xxxs); margin-right: var(--space-xxxs); }
        .margin-x-xxs\@#{$breakpoint}   { margin-left: var(--space-xxs); margin-right: var(--space-xxs); }
        .margin-x-xs\@#{$breakpoint}    { margin-left: var(--space-xs); margin-right: var(--space-xs); }
        .margin-x-sm\@#{$breakpoint}    { margin-left: var(--space-sm); margin-right: var(--space-sm); }
        .margin-x-md\@#{$breakpoint}    { margin-left: var(--space-md); margin-right: var(--space-md); }
        .margin-x-lg\@#{$breakpoint}    { margin-left: var(--space-lg); margin-right: var(--space-lg); }
        .margin-x-xl\@#{$breakpoint}    { margin-left: var(--space-xl); margin-right: var(--space-xl); }
        .margin-x-xxl\@#{$breakpoint}   { margin-left: var(--space-xxl); margin-right: var(--space-xxl); }
        .margin-x-xxxl\@#{$breakpoint}  { margin-left: var(--space-xxxl); margin-right: var(--space-xxxl); }
        .margin-x-xxxxl\@#{$breakpoint} { margin-left: var(--space-xxxxl); margin-right: var(--space-xxxxl); }
        .margin-x-auto\@#{$breakpoint}  { margin-left: auto; margin-right: auto; }
        .margin-x-0\@#{$breakpoint}     { margin-left: 0; margin-right: 0; }

        .margin-y-xxxxs\@#{$breakpoint} { margin-top: var(--space-xxxxs); margin-bottom: var(--space-xxxxs); }
        .margin-y-xxxs\@#{$breakpoint}  { margin-top: var(--space-xxxs); margin-bottom: var(--space-xxxs); }
        .margin-y-xxs\@#{$breakpoint}   { margin-top: var(--space-xxs); margin-bottom: var(--space-xxs); }
        .margin-y-xs\@#{$breakpoint}    { margin-top: var(--space-xs); margin-bottom: var(--space-xs); }
        .margin-y-sm\@#{$breakpoint}    { margin-top: var(--space-sm); margin-bottom: var(--space-sm); }
        .margin-y-md\@#{$breakpoint}    { margin-top: var(--space-md); margin-bottom: var(--space-md); }
        .margin-y-lg\@#{$breakpoint}    { margin-top: var(--space-lg); margin-bottom: var(--space-lg); }
        .margin-y-xl\@#{$breakpoint}    { margin-top: var(--space-xl); margin-bottom: var(--space-xl); }
        .margin-y-xxl\@#{$breakpoint}   { margin-top: var(--space-xxl); margin-bottom: var(--space-xxl); }
        .margin-y-xxxl\@#{$breakpoint}  { margin-top: var(--space-xxxl); margin-bottom: var(--space-xxxl); }
        .margin-y-xxxxl\@#{$breakpoint} { margin-top: var(--space-xxxxl); margin-bottom: var(--space-xxxxl); }
        .margin-y-auto\@#{$breakpoint}  { margin-top: auto; margin-bottom: auto; }
        .margin-y-0\@#{$breakpoint}     { margin-top: 0; margin-bottom: 0; }
      }

      // padding
      @supports (--css: variables) {
        .padding-xxxxs\@#{$breakpoint} { padding: var(--space-xxxxs); }
        .padding-xxxs\@#{$breakpoint}  { padding: var(--space-xxxs); }
        .padding-xxs\@#{$breakpoint}   { padding: var(--space-xxs); }
        .padding-xs\@#{$breakpoint}    { padding: var(--space-xs); }
        .padding-sm\@#{$breakpoint}    { padding: var(--space-sm); }
        .padding-md\@#{$breakpoint}    { padding: var(--space-md); }
        .padding-lg\@#{$breakpoint}    { padding: var(--space-lg); }
        .padding-xl\@#{$breakpoint}    { padding: var(--space-xl); }
        .padding-xxl\@#{$breakpoint}   { padding: var(--space-xxl); }
        .padding-xxxl\@#{$breakpoint}  { padding: var(--space-xxxl); }
        .padding-xxxxl\@#{$breakpoint} { padding: var(--space-xxxxl); }
        .padding-0\@#{$breakpoint}     { padding: 0; }
        .padding-component\@#{$breakpoint} { padding: var(--component-padding); }

        .padding-top-xxxxs\@#{$breakpoint} { padding-top: var(--space-xxxxs); }
        .padding-top-xxxs\@#{$breakpoint}  { padding-top: var(--space-xxxs); }
        .padding-top-xxs\@#{$breakpoint}   { padding-top: var(--space-xxs); }
        .padding-top-xs\@#{$breakpoint}    { padding-top: var(--space-xs); }
        .padding-top-sm\@#{$breakpoint}    { padding-top: var(--space-sm); }
        .padding-top-md\@#{$breakpoint}    { padding-top: var(--space-md); }
        .padding-top-lg\@#{$breakpoint}    { padding-top: var(--space-lg); }
        .padding-top-xl\@#{$breakpoint}    { padding-top: var(--space-xl); }
        .padding-top-xxl\@#{$breakpoint}   { padding-top: var(--space-xxl); }
        .padding-top-xxxl\@#{$breakpoint}  { padding-top: var(--space-xxxl); }
        .padding-top-xxxxl\@#{$breakpoint} { padding-top: var(--space-xxxxl); }
        .padding-top-0\@#{$breakpoint}     { padding-top: 0; }
        .padding-top-component\@#{$breakpoint} { padding-top: var(--component-padding); }

        .padding-bottom-xxxxs\@#{$breakpoint} { padding-bottom: var(--space-xxxxs); }
        .padding-bottom-xxxs\@#{$breakpoint}  { padding-bottom: var(--space-xxxs); }
        .padding-bottom-xxs\@#{$breakpoint}   { padding-bottom: var(--space-xxs); }
        .padding-bottom-xs\@#{$breakpoint}    { padding-bottom: var(--space-xs); }
        .padding-bottom-sm\@#{$breakpoint}    { padding-bottom: var(--space-sm); }
        .padding-bottom-md\@#{$breakpoint}    { padding-bottom: var(--space-md); }
        .padding-bottom-lg\@#{$breakpoint}    { padding-bottom: var(--space-lg); }
        .padding-bottom-xl\@#{$breakpoint}    { padding-bottom: var(--space-xl); }
        .padding-bottom-xxl\@#{$breakpoint}   { padding-bottom: var(--space-xxl); }
        .padding-bottom-xxxl\@#{$breakpoint}  { padding-bottom: var(--space-xxxl); }
        .padding-bottom-xxxxl\@#{$breakpoint} { padding-bottom: var(--space-xxxxl); }
        .padding-bottom-0\@#{$breakpoint}     { padding-bottom: 0; }
        .padding-bottom-component\@#{$breakpoint} { padding-bottom: var(--component-padding); }

        .padding-right-xxxxs\@#{$breakpoint} { padding-right: var(--space-xxxxs); }
        .padding-right-xxxs\@#{$breakpoint}  { padding-right: var(--space-xxxs); }
        .padding-right-xxs\@#{$breakpoint}   { padding-right: var(--space-xxs); }
        .padding-right-xs\@#{$breakpoint}    { padding-right: var(--space-xs); }
        .padding-right-sm\@#{$breakpoint}    { padding-right: var(--space-sm); }
        .padding-right-md\@#{$breakpoint}    { padding-right: var(--space-md); }
        .padding-right-lg\@#{$breakpoint}    { padding-right: var(--space-lg); }
        .padding-right-xl\@#{$breakpoint}    { padding-right: var(--space-xl); }
        .padding-right-xxl\@#{$breakpoint}   { padding-right: var(--space-xxl); }
        .padding-right-xxxl\@#{$breakpoint}  { padding-right: var(--space-xxxl); }
        .padding-right-xxxxl\@#{$breakpoint} { padding-right: var(--space-xxxxl); }
        .padding-right-0\@#{$breakpoint}     { padding-right: 0; }
        .padding-right-component\@#{$breakpoint} { padding-right: var(--component-padding); }

        .padding-left-xxxxs\@#{$breakpoint} { padding-left: var(--space-xxxxs); }
        .padding-left-xxxs\@#{$breakpoint}  { padding-left: var(--space-xxxs); }
        .padding-left-xxs\@#{$breakpoint}   { padding-left: var(--space-xxs); }
        .padding-left-xs\@#{$breakpoint}    { padding-left: var(--space-xs); }
        .padding-left-sm\@#{$breakpoint}    { padding-left: var(--space-sm); }
        .padding-left-md\@#{$breakpoint}    { padding-left: var(--space-md); }
        .padding-left-lg\@#{$breakpoint}    { padding-left: var(--space-lg); }
        .padding-left-xl\@#{$breakpoint}    { padding-left: var(--space-xl); }
        .padding-left-xxl\@#{$breakpoint}   { padding-left: var(--space-xxl); }
        .padding-left-xxxl\@#{$breakpoint}  { padding-left: var(--space-xxxl); }
        .padding-left-xxxxl\@#{$breakpoint} { padding-left: var(--space-xxxxl); }
        .padding-left-0\@#{$breakpoint}     { padding-left: 0; }
        .padding-left-component\@#{$breakpoint} { padding-left: var(--component-padding); }

        .padding-x-xxxxs\@#{$breakpoint} { padding-left: var(--space-xxxxs); padding-right: var(--space-xxxxs); }
        .padding-x-xxxs\@#{$breakpoint}  { padding-left: var(--space-xxxs); padding-right: var(--space-xxxs); }
        .padding-x-xxs\@#{$breakpoint}   { padding-left: var(--space-xxs); padding-right: var(--space-xxs); }
        .padding-x-xs\@#{$breakpoint}    { padding-left: var(--space-xs); padding-right: var(--space-xs); }
        .padding-x-sm\@#{$breakpoint}    { padding-left: var(--space-sm); padding-right: var(--space-sm); }
        .padding-x-md\@#{$breakpoint}    { padding-left: var(--space-md); padding-right: var(--space-md); }
        .padding-x-lg\@#{$breakpoint}    { padding-left: var(--space-lg); padding-right: var(--space-lg); }
        .padding-x-xl\@#{$breakpoint}    { padding-left: var(--space-xl); padding-right: var(--space-xl); }
        .padding-x-xxl\@#{$breakpoint}   { padding-left: var(--space-xxl); padding-right: var(--space-xxl); }
        .padding-x-xxxl\@#{$breakpoint}  { padding-left: var(--space-xxxl); padding-right: var(--space-xxxl); }
        .padding-x-xxxxl\@#{$breakpoint} { padding-left: var(--space-xxxxl); padding-right: var(--space-xxxxl); }
        .padding-x-0\@#{$breakpoint}     { padding-left: 0; padding-right: 0; }
        .padding-x-component\@#{$breakpoint} { padding-left: var(--component-padding); padding-right: var(--component-padding); }

        .padding-y-xxxxs\@#{$breakpoint} { padding-top: var(--space-xxxxs); padding-bottom: var(--space-xxxxs); }
        .padding-y-xxxs\@#{$breakpoint}  { padding-top: var(--space-xxxs); padding-bottom: var(--space-xxxs); }
        .padding-y-xxs\@#{$breakpoint}   { padding-top: var(--space-xxs); padding-bottom: var(--space-xxs); }
        .padding-y-xs\@#{$breakpoint}    { padding-top: var(--space-xs); padding-bottom: var(--space-xs); }
        .padding-y-sm\@#{$breakpoint}    { padding-top: var(--space-sm); padding-bottom: var(--space-sm); }
        .padding-y-md\@#{$breakpoint}    { padding-top: var(--space-md); padding-bottom: var(--space-md); }
        .padding-y-lg\@#{$breakpoint}    { padding-top: var(--space-lg); padding-bottom: var(--space-lg); }
        .padding-y-xl\@#{$breakpoint}    { padding-top: var(--space-xl); padding-bottom: var(--space-xl); }
        .padding-y-xxl\@#{$breakpoint}   { padding-top: var(--space-xxl); padding-bottom: var(--space-xxl); }
        .padding-y-xxxl\@#{$breakpoint}  { padding-top: var(--space-xxxl); padding-bottom: var(--space-xxxl); }
        .padding-y-xxxxl\@#{$breakpoint} { padding-top: var(--space-xxxxl); padding-bottom: var(--space-xxxxl); }
        .padding-y-0\@#{$breakpoint}     { padding-top: 0; padding-bottom: 0; }
        .padding-y-component\@#{$breakpoint} { padding-top: var(--component-padding); padding-bottom: var(--component-padding); }
      }

      // text-align
      .text-center\@#{$breakpoint} { text-align: center; }
      .text-left\@#{$breakpoint}   { text-align: left; }
      .text-right\@#{$breakpoint}  { text-align: right; }
      .text-justify\@#{$breakpoint}  { text-align: justify; }

      // font-size
      @supports (--css: variables) {
        .text-xs\@#{$breakpoint}   { font-size: var(--text-xs, 0.694em); }
        .text-sm\@#{$breakpoint}   { font-size: var(--text-sm, 0.833em); }
        .text-base\@#{$breakpoint} { font-size: var(--text-unit, 1em); }
        .text-md\@#{$breakpoint}   { font-size: var(--text-md, 1.2em); }
        .text-lg\@#{$breakpoint}   { font-size: var(--text-lg, 1.44em); }
        .text-xl\@#{$breakpoint}   { font-size: var(--text-xl, 1.728em); }
        .text-xxl\@#{$breakpoint}  { font-size: var(--text-xxl, 2.074em); }
        .text-xxxl\@#{$breakpoint} { font-size: var(--text-xxxl, 2.488em); }
        .text-xxxxl\@#{$breakpoint} { font-size: var(--text-xxxxl, 2.985em); }
      }

      // width
      @supports (--css: variables) {
        .width-xxxxs\@#{$breakpoint} { width: var(--size-xxxxs, 0.25rem); }
        .width-xxxs\@#{$breakpoint}  { width: var(--size-xxxs, 0.5rem); }
        .width-xxs\@#{$breakpoint}   { width: var(--size-xxs, 0.75rem); }
        .width-xs\@#{$breakpoint}    { width: var(--size-xs, 1rem); }
        .width-sm\@#{$breakpoint}    { width: var(--size-sm, 1.5rem); }
        .width-md\@#{$breakpoint}    { width: var(--size-md, 2rem); }
        .width-lg\@#{$breakpoint}    { width: var(--size-lg, 3rem); }
        .width-xl\@#{$breakpoint}    { width: var(--size-xl, 4rem); }
        .width-xxl\@#{$breakpoint}   { width: var(--size-xxl, 6rem); }
        .width-xxxl\@#{$breakpoint}  { width: var(--size-xxxl, 8rem); }
        .width-xxxxl\@#{$breakpoint} { width: var(--size-xxxxl, 16rem); }
      }

      .width-0\@#{$breakpoint}     { width: 0; }
      .width-10\%\@#{$breakpoint}  { width: 10%; }
      .width-20\%\@#{$breakpoint}  { width: 20%; }
      .width-25\%\@#{$breakpoint}  { width: 25%; }
      .width-30\%\@#{$breakpoint}  { width: 30%; }
      .width-33\%\@#{$breakpoint}  { width: calc(100% / 3); }
      .width-40\%\@#{$breakpoint}  { width: 40%; }
      .width-50\%\@#{$breakpoint}  { width: 50%; }
      .width-60\%\@#{$breakpoint}  { width: 60%; }
      .width-66\%\@#{$breakpoint}  { width: calc(100% / 1.5); }
      .width-70\%\@#{$breakpoint}  { width: 70%; }
      .width-75\%\@#{$breakpoint}  { width: 75%; }
      .width-80\%\@#{$breakpoint}  { width: 80%; }
      .width-90\%\@#{$breakpoint}  { width: 90%; }
      .width-100\%\@#{$breakpoint} { width: 100%; }
      .width-100vw\@#{$breakpoint} { width: 100vw; }
      .width-auto\@#{$breakpoint}  { width: auto; }

      // height
      @supports (--css: variables) {
        .height-xxxxs\@#{$breakpoint} { height: var(--size-xxxxs, 0.25rem); }
        .height-xxxs\@#{$breakpoint}  { height: var(--size-xxxs, 0.5rem); }
        .height-xxs\@#{$breakpoint}   { height: var(--size-xxs, 0.75rem); }
        .height-xs\@#{$breakpoint}    { height: var(--size-xs, 1rem); }
        .height-sm\@#{$breakpoint}    { height: var(--size-sm, 1.5rem); }
        .height-md\@#{$breakpoint}    { height: var(--size-md, 2rem); }
        .height-lg\@#{$breakpoint}    { height: var(--size-lg, 3rem); }
        .height-xl\@#{$breakpoint}    { height: var(--size-xl, 4rem); }
        .height-xxl\@#{$breakpoint}   { height: var(--size-xxl, 6rem); }
        .height-xxxl\@#{$breakpoint}  { height: var(--size-xxxl, 8rem); }
        .height-xxxxl\@#{$breakpoint} { height: var(--size-xxxxl, 16rem); }
      }

      .height-0\@#{$breakpoint}     { height: 0; }
      .height-10\%\@#{$breakpoint}  { height: 10%; }
      .height-20\%\@#{$breakpoint}  { height: 20%; }
      .height-25\%\@#{$breakpoint}  { height: 25%; }
      .height-30\%\@#{$breakpoint}  { height: 30%; }
      .height-33\%\@#{$breakpoint}  { height: calc(100% / 3); }
      .height-40\%\@#{$breakpoint}  { height: 40%; }
      .height-50\%\@#{$breakpoint}  { height: 50%; }
      .height-60\%\@#{$breakpoint}  { height: 60%; }
      .height-66\%\@#{$breakpoint}  { height: calc(100% / 1.5); }
      .height-70\%\@#{$breakpoint}  { height: 70%; }
      .height-75\%\@#{$breakpoint}  { height: 75%; }
      .height-80\%\@#{$breakpoint}  { height: 80%; }
      .height-90\%\@#{$breakpoint}  { height: 90%; }
      .height-100\%\@#{$breakpoint} { height: 100%; }
      .height-100vh\@#{$breakpoint} { height: 100vh; }
      .height-auto\@#{$breakpoint}  { height: auto; }

      // position
      .position-relative\@#{$breakpoint} { position: relative; }
      .position-absolute\@#{$breakpoint} { position: absolute; }
      .position-fixed\@#{$breakpoint}    { position: fixed; }
      .position-sticky\@#{$breakpoint}   { position: sticky; }
      .position-static\@#{$breakpoint}   { position: static; }

      .inset-0\@#{$breakpoint}     { top: 0; right: 0; bottom: 0; left: 0; }

      .top-0\@#{$breakpoint}       { top: 0; }
      .top-50\%\@#{$breakpoint}    { top: 50%; }

      .bottom-0\@#{$breakpoint}    { bottom: 0; }
      .bottom-50\%\@#{$breakpoint} { bottom: 50%; }

      .right-0\@#{$breakpoint}     { right: 0; }
      .right-50\%\@#{$breakpoint}  { right: 50%; }

      .left-0\@#{$breakpoint}      { left: 0; }
      .left-50\%\@#{$breakpoint}   { left: 50%; }

      @supports (--css: variables) {
        .top-xxxxs\@#{$breakpoint} { top: var(--space-xxxxs); }
        .top-xxxs\@#{$breakpoint}  { top: var(--space-xxxs); }
        .top-xxs\@#{$breakpoint}   { top: var(--space-xxs); }
        .top-xs\@#{$breakpoint}    { top: var(--space-xs); }
        .top-sm\@#{$breakpoint}    { top: var(--space-sm); }
        .top-md\@#{$breakpoint}    { top: var(--space-md); }
        .top-lg\@#{$breakpoint}    { top: var(--space-lg); }
        .top-xl\@#{$breakpoint}    { top: var(--space-xl); }
        .top-xxl\@#{$breakpoint}   { top: var(--space-xxl); }
        .top-xxxl\@#{$breakpoint}  { top: var(--space-xxxl); }
        .top-xxxxl\@#{$breakpoint} { top: var(--space-xxxxl); }

        .bottom-xxxxs\@#{$breakpoint} { bottom: var(--space-xxxxs); }
        .bottom-xxxs\@#{$breakpoint}  { bottom: var(--space-xxxs); }
        .bottom-xxs\@#{$breakpoint}   { bottom: var(--space-xxs); }
        .bottom-xs\@#{$breakpoint}    { bottom: var(--space-xs); }
        .bottom-sm\@#{$breakpoint}    { bottom: var(--space-sm); }
        .bottom-md\@#{$breakpoint}    { bottom: var(--space-md); }
        .bottom-lg\@#{$breakpoint}    { bottom: var(--space-lg); }
        .bottom-xl\@#{$breakpoint}    { bottom: var(--space-xl); }
        .bottom-xxl\@#{$breakpoint}   { bottom: var(--space-xxl); }
        .bottom-xxxl\@#{$breakpoint}  { bottom: var(--space-xxxl); }
        .bottom-xxxxl\@#{$breakpoint} { bottom: var(--space-xxxxl); }

        .right-xxxxs\@#{$breakpoint} { right: var(--space-xxxxs); }
        .right-xxxs\@#{$breakpoint}  { right: var(--space-xxxs); }
        .right-xxs\@#{$breakpoint}   { right: var(--space-xxs); }
        .right-xs\@#{$breakpoint}    { right: var(--space-xs); }
        .right-sm\@#{$breakpoint}    { right: var(--space-sm); }
        .right-md\@#{$breakpoint}    { right: var(--space-md); }
        .right-lg\@#{$breakpoint}    { right: var(--space-lg); }
        .right-xl\@#{$breakpoint}    { right: var(--space-xl); }
        .right-xxl\@#{$breakpoint}   { right: var(--space-xxl); }
        .right-xxxl\@#{$breakpoint}  { right: var(--space-xxxl); }
        .right-xxxxl\@#{$breakpoint} { right: var(--space-xxxxl); }

        .left-xxxxs\@#{$breakpoint} { left: var(--space-xxxxs); }
        .left-xxxs\@#{$breakpoint}  { left: var(--space-xxxs); }
        .left-xxs\@#{$breakpoint}   { left: var(--space-xxs); }
        .left-xs\@#{$breakpoint}    { left: var(--space-xs); }
        .left-sm\@#{$breakpoint}    { left: var(--space-sm); }
        .left-md\@#{$breakpoint}    { left: var(--space-md); }
        .left-lg\@#{$breakpoint}    { left: var(--space-lg); }
        .left-xl\@#{$breakpoint}    { left: var(--space-xl); }
        .left-xxl\@#{$breakpoint}   { left: var(--space-xxl); }
        .left-xxxl\@#{$breakpoint}  { left: var(--space-xxxl); }
        .left-xxxxl\@#{$breakpoint} { left: var(--space-xxxxl); }
      }

      // overflow
      .overflow-hidden\@#{$breakpoint} { overflow: hidden; }
      .overflow-auto\@#{$breakpoint}   { overflow: auto; }
      .momentum-scrolling\@#{$breakpoint} { -webkit-overflow-scrolling: touch; }
      .overscroll-contain\@#{$breakpoint} { overscroll-behavior: contain; }

      // visibility
      .hide\@#{$breakpoint} { display: none !important; }
    }

    @include breakpoint(#{$breakpoint}, "not all") {
      .has-margin\@#{$breakpoint} { margin: 0 !important; }
      .has-padding\@#{$breakpoint} { padding: 0 !important; }
      .display\@#{$breakpoint} { display: none !important; }
    }
  }
}
