html {
  scroll-behavior: smooth;
}

body {
  font-weight: 300;
}

img {
  image-rendering: -webkit-optimize-contrast;
}

.lamp-anim {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.float-nav-header {
  -webkit-backdrop-filter: none !important;
  backdrop-filter: none !important; 
}

.doodle-hero {
  position: relative;
 
  .doodle-hero-image {
    position: relative;
    z-index: 10;

    video { 
      border-radius: 30px; 
      opacity: 1;
      border: solid 15px rgba(255,255,255,.5);

    }

    img { border-radius: 40px; }

    .doodle-hero-content {
      position: absolute;
      margin: 0 auto;
      top: 22%;
      width: 100%;
      text-align: center;

      h1 { color: #fff; }
      a { color: #000; }

     

    }

  }

  .doodle-hero-parallax {
    z-index: 11;
    position: relative;
  }

}

.doodle-ecom-items {

  .feature-v6__item img {
    border-radius: 10px;
  }

}

.doodle-ecom-list {
  margin-top: 30px;
  font-size: .85em;

  .items-start { text-align: left; }

  b { font-weight: 500; color: var(--color-primary); }

}

.doodle-ecom-meta {
  max-width: 500px; 
}

.doodle-ecom-price { 
  margin: 20px 0; 
  strike {
    font-weight: 100;
    color: #ccc;
    margin-right: 3px;
  }

}

.doodle-ecom-featured {

  h2 { 
    margin-top: 0; 
    color: #185459; 
    font-weight: 500; 
  }

  .award-image {
    max-width: 600px;
    margin: 0;
    margin-bottom: 20px;
    opacity: .85;
    margin-top: 40px;
    width: 100%;
  }

  .featured-copy { 
    max-width: 900px; 
    margin: 0 auto;
    line-height: 1.4em;
  }

  @media screen and (max-width: 500px) {

    .ecom-prod { margin-top: 40px; }
    .max-width-md { width: 100%; }
    .ecom-prod:first-of-type { 
      margin-top: 0; 
    }

  }

}

.doodle-amazon {

  .doodle-amazon-img {
    max-width: 300px;

    @media screen and (max-width: 1024px) {
      max-width: 470px;
      margin-top: 8px;
      width: 100%;
    }
  }

}

.doodle-demo {

  overflow: hidden;

  @media screen and (min-width: 500px) {

      .doodle-demo-copy {
        max-width: 400px;
        margin: 0 auto;
      }

      .doodle-demo-image {
        position: relative;
        padding: 0 5vw;
        margin-top: -150px;
      }

  }

  @media screen and (min-width: 501px) and (max-width: 1100px) {

    .doodle-demo-copy { font-size: 90%; max-width: 320px; }
    .doodle-demo-image {
      margin-top: -50px; 
    }

  }

  

}



.doodle-immersive {
  position: relative;
}


.doodle-gallery {

    background-color: #f6f6f6;

  .position-sticky {

    background: url('/img/gallery-bg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;

  }

  .doodle-gallery__content { 
    margin-top: -450px; 
  
    @media screen and (max-width: 500px) {
      margin-top: -330px; 
    }

    @media screen and (max-width: 320px) {
      margin-top: -210px; 
    }

  }

  .flex { flex-direction: column; }

  .doodle-gallery__scroller {

    margin-top: 35px;

    a {
      position: relative;
      bottom: 30%;
      z-index: 2;
      display: inline-block;
      color: #ddd;
      text-decoration: none;
      transition: opacity .3s;
      font-weight: 500;
    }
    a span {
      position: absolute;
      top: 30px;
      left: 50%;
      width: 30px;
      height: 50px;
      margin-left: -15px;
      border: 3px solid #ddd;
      border-radius: 50px;
      box-sizing: border-box;
    }
    a span::before {
      position: absolute;
      top: 10px;
      left: 50%;
      content: '';
      width: 6px;
      height: 6px;
      margin-left: -3px;
      background-color: #ccc;
      border-radius: 100%;
      -webkit-animation: sdb10 2s infinite;
      animation: sdb10 2s infinite;
      box-sizing: border-box;
    }

    @-webkit-keyframes sdb10 {
      0% {
        -webkit-transform: translate(0, 20px);
        opacity: 0;
      }
      40% {
        opacity: 1;
      }
      80% {
        -webkit-transform: translate(0, 0px);
        opacity: 0;
      }
      100% {
        opacity: 0;
      }
    }

    @keyframes sdb10 {
      0% {
        transform: translate(0, 20px);
        opacity: 0;
      }
      40% {
        opacity: 1;
      }
      80% {
        transform: translate(0, 0px);
        opacity: 0;
      }
      100% {
        opacity: 0;
      }
    }

  }

}

.doodle-faq {

  .accordion__item {
    border-color: rgba(255,255,255,.2);

    a { color: var(--color-accent); font-weight: 900; }

  }

  .accordion__header {
    font-size: .9em;
    font-weight: 500;
  }

  .accordion__panel p {
    opacity: .7;
  }

}


.doodle-features {
  
  h4 { color: #fff; }
  
  .flex img { max-width: 100px; }
  
  .doodle-features__pen { 
    position: relative;
    left: -30px; 
  }

  @media screen and (max-width: 1024px) {

    .doodle-features__center { 
      display: none; 
    }

  }
  
}


.doodle-tour {
  background-color: #185459;

  @media screen and (max-width: 1024px) {

    .doodle-tour-wrap {

      .container { 
        width: 100%; 
        margin-bottom: 45px; 

        .lg-container { 
          margin: 0;
          left: -10px;
          right: -10px;
          width: 110%;
         }

         .lg-hotspot__button { width: 25px; height: 25px; }

      }

    }

  }

}


.validation-bars {
  padding: 3em 1em 2em;
  color: #777;
  margin: 0 auto;
  background-color: var(--color-contrast-lower);

  .detail-text {
    font-weight: 600;
    letter-spacing: 5px;
    text-transform: uppercase;
    color: #444;
    padding: 0 15px;
    line-height: 1.5em;

  }

}

.validation-bars-wrap {
  max-width: 1400px;
  margin: 0 auto;
}

.validation-bars .flickity-button-icon {
  fill: #bbb
}

@media screen and (max-width: 60em) {
  .validation-bars {
      padding:1em
  }
}

.validation-bars .grid__item {
  text-align: center;
  padding: 0 1.6em;
  padding-top: 10px;
  width: 20%;
  float: left; 
}

@media screen and (max-width: 60em) {
  .validation-bars .grid__item {
      width:100%;
      padding-left: 0;
      padding-right: 0
  }
}

.validation-bars .validation-bar__tp {
  height: 80px;
  overflow: hidden;
  padding-top: 10px;
  opacity: .5
}

.validation-bars .validation-bar__quote {
  font-weight: 300;
  font-size: .9em;
  margin-bottom: 0;
  min-height: 70px;
  color: #777;
  line-height: 1.4em;

  b { font-weight: 700; color: #185459; }

}

@media screen and (max-width: 60em) {
  .validation-bars .validation-bar__quote {
      padding:.5em 4em;
      min-height: 100%
  }

  .validation-bar__stars {
    background-color: #fff;
    border-radius: 10px;
    margin: 1.5em 0;
  }

}

.validation-bars .validation-bar__image {
  margin-top: -10px;
  max-width: 170px
}

.validation-bars .validation-bar__stars img {
  max-width: 150px
}

.validation-bars .validation-bar__stars a {
  display: block;
  margin: 15px 0 19px;
  font-size: .9em;
  font-weight: 600;
  color: #185459;
}


@media screen and (max-width: 1024px) {

  .float-nav-header__logo img { width: 95px; }
  .float-nav-header__menu-btn { background-color: rgba(255,255,255,.75) !important; }
  
  .doodle-hero .doodle-hero-image { top: 20px; }

  .doodle-hero .doodle-hero-image .doodle-hero-content h1 { 
    font-size: 26px;
    margin-bottom: 7px;
    margin-top: -7px;
  }

  .doodle-hero .doodle-hero-image video {
    border: solid 6px rgba(255,255,255,.5);
    border-radius: 10px; 
}

  .doodle-hero-parallax .col-4.js-scroll-fx { 
    -webkit-transform: none !important;
    transform: none !important;
  }
  .doodle-hero .doodle-hero-parallax { top: -15px; }
  .float-nav-header__menu-btn .icon {
    font-size: 18px !important;
    margin-top: -2px;
  }
  .float-nav-header__menu-btn span {
    text-transform: uppercase;
    font-weight: bold;
    font-size: .9em;
  }

}