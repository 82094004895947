/* -------------------------------- 

File#: _1_video-feature
Title: Video Feature
Descr: A CTA box over a background video
Usage: codyhouse.co/license

-------------------------------- */

.video-feature {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.video-feature__box-wrapper {
  position: relative;
  z-index: 2;
}

.video-feature__box {
  background-color: alpha(var(--color-bg), 0.98);

  @supports (backdrop-filter: blur(10px)) {
    background-color: alpha(var(--color-bg), 0.8);
    backdrop-filter: blur(10px);
  }
}

.video-feature__video-wrapper {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  img, video {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  img { // video fallback - visible if Reduced Motion is enabled
    display: none;
  }

  @media (prefers-reduced-motion: reduce) { // don't play video if Reduced Motion is enabled - show fallback image
    video { 
      visibility: hidden;
    }

    img {
      display: block;
    }
  }
}

.video-feature__link {
  --transition-duration: .4s;
  position: relative;
  z-index: 1;
  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: alpha(var(--color-contrast-higher), 0.075);
  border-radius: inherit;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  
  color: var(--color-contrast-higher);
  text-decoration: none;
  @include fontSmooth;

  transition: var(--transition-duration);

  > * {
    position: relative;
    z-index: 2;
  }

  &::before { // animated background
    content: '';
    position: absolute;
    bottom: -20%;
    left: -5%;
    width: 110%;
    height: 140%;
    background-color: alpha(var(--color-contrast-higher), 0.9);
    
    will-change: transform;
    transform: scaleY(0) skewY(10deg);
    transform-origin: center bottom;
    transition: transform var(--transition-duration) var(--ease-in-out);
  }

  &:hover {
    color: var(--color-bg);

    &::before {
      transform: scaleY(1) skewY(0deg);
    }

    .video-feature__link-icon {
      > *:first-child {
        transform: rotate(-180deg);
      }

      > *:last-child {
        transform: rotate(180deg);
      }
    }
  }
}

.video-feature__link-icon { // arrow icon
  > * {
    will-change: transform;
    transition: transform var(--transition-duration) var(--ease-in-out);
  }

  > *:first-child {
    transform-origin: 8px 5px;
  }

  > *:last-child {
    transform-origin: 8px 11px;
  }
}

