.section-twitter {

    .section-twitter__grid {
        width:100%;
        max-width: 1270px;
        margin: 50px auto;
      }

      .section-twitter__griditem {
        width:300px;
        float:left;
      }

      @media screen and (max-width: 500px) {

        .section-twitter__grid { margin-top: 30px; }
        .section-twitter__griditem { width: 100%; }

        .section-twitter__griditem:nth-of-type(9),
        .section-twitter__griditem:nth-of-type(10),
        .section-twitter__griditem:nth-of-type(11),
        .section-twitter__griditem:nth-of-type(12),
        .section-twitter__griditem:nth-of-type(13),
        .section-twitter__griditem:nth-of-type(14),
        .section-twitter__griditem:nth-of-type(15),
        .section-twitter__griditem:nth-of-type(16) { display: none; }

      }
      
}