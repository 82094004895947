@font-face {
    font-family: 'Gotham Rounded';
    font-weight: 900;
    src:  url('../fonts/GothamRoundedBold.woff') format('woff'),
          url('../fonts/GothamRoundedBold.ttf') format('ttf');
  }
  
  @font-face {
    font-family: 'Gotham Rounded';
    font-weight: 500;
    src:  url('../fonts/GothamRoundedMedium.woff') format('woff'),
          url('../fonts/GothamRoundedMedium.ttf') format('ttf');
  }

  @font-face {
      font-family: 'Gotham Rounded';
      font-weight: 300;
      src:  url('../fonts/GothamRoundedBook.svg') format('svg'),
            url('../fonts/GothamRoundedBook.woff') format('woff'),
            url('../fonts/GothamRoundedBook.ttf') format('ttf');
    }
  
  @font-face {
    font-family: 'Gotham Rounded';
    font-weight: 100;
    src:  url('../fonts/GothamRoundedLight.woff2') format('woff2'),
          url('../fonts/GothamRoundedLight.woff') format('woff'),
          url('../fonts/GothamRoundedLight.ttf') format('ttf');
  }

