/* --------------------------------

File#: _1_animated-headline
Title: Animated Headline
Descr: A collection of word replacement effects
Usage: codyhouse.co/license

-------------------------------- */

.text-anim {}

.text-anim__wrapper {
  position: relative;
  display: inline-block;
}

.js {
  .text-anim__word {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    display: inline-block;
    white-space: nowrap;
  }

  .text-anim__word--in {
    position: relative;
    z-index: 2;
  }
}

// --rotate
.js .text-anim--rotate {
  --text-anim-duration: 0.85s;
  --text-anim-pause: 2.5s;

  .text-anim__wrapper {
    perspective: 300px;
  }

  .text-anim__word {
    opacity: 0;
    backface-visibility: hidden;
    transform-origin: 50% 100%;
    transform: rotateX(-180deg);
    transition: opacity calc(var(--text-anim-duration) / 3) var(--ease-out), transform var(--text-anim-duration) var(--ease-out-back);
  }

  .text-anim__word--in {
    opacity: 1;
    transform: rotateX(0deg);
  }

  .text-anim__word--out {
    opacity: 0;
    transform: rotateX(180deg);
    transition: opacity calc(var(--text-anim-duration) / 6) var(--ease-out), transform var(--text-anim-duration) var(--ease-out-back);
  }
}

// --loader
.js .text-anim--loader {
  --text-anim-duration: 2.5s;
  --text-anim-pause: 0s;

  .text-anim__word {
    color: transparent;
    transition: color .2s;

    &::after { // animated loader
      content: '';
      position: absolute;
      left: 0;
      bottom: -2px;
      height: 2px;
      width: 100%;
      background-color: currentColor;
      transform-origin: left center;
      transform: translateY(100%) scaleX(0);
      transition: transform var(--text-anim-duration) var(--ease-out);
    }
  }

  .text-anim__word--in {
    color: currentColor;

    &::after {
      transform: translateY(100%) scaleX(1);
    }
  }

  .text-anim__word--out {
    color: transparent;

    &::after {
      transition: none;
      transform: translateY(100%) scaleX(0);
    }
  }
}

// --slide
.js .text-anim--slide {
  --text-anim-duration: 0.5s;
  --text-anim-pause: 2.5s;

  .text-anim__wrapper {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    padding: 0.1em 0;
  }

  .text-anim__word {
    height: 100%;
    transform: translateY(-100%);
  }

  .text-anim__word--in,
  .text-anim__word--out {
    animation-duration: var(--text-anim-duration);
    animation-timing-function: var(--ease-out-back);
  }

  .text-anim__word--in {
    animation-name: text-anim-slide-in;
    animation-fill-mode: forwards;
  }

  .text-anim__word--out {
    animation-name: text-anim-slide-out;
  }
}

@keyframes text-anim-slide-in {
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0%);
  }
}

@keyframes text-anim-slide-out {
  from {
    transform: translateY(0%);
  }

  to {
    transform: translateY(100%);
  }
}

// --zoom
.js .text-anim--zoom {
  --text-anim-duration: 0.85s;
  --text-anim-pause: 2.5s;

  .text-anim__wrapper {
    perspective: 300px;
  }

  .text-anim__word {
    opacity: 0;
    transform: translateZ(100px);
    transition: opacity var(--text-anim-duration), transform var(--text-anim-duration) var(--ease-out);
  }

  .text-anim__word--in {
    opacity: 1;
    transform: translateZ(0);
  }

  .text-anim__word--out {
    opacity: 0;
    transform: translateZ(-100px);
  }
}

// --push
.js .text-anim--push {
  --text-anim-duration: 0.65s;
  --text-anim-pause: 2.5s;

  .text-anim__word {
    opacity: 0;
    transform: translateX(-100px);
    transition: none;
  }

  .text-anim__word--in,
  .text-anim__word--out {
    transition: opacity var(--text-anim-duration), transform var(--text-anim-duration) var(--ease-out-back);
  }

  .text-anim__word--in {
    opacity: 1;
    transform: translateX(0);
  }

  .text-anim__word--out {
    opacity: 0;
    transform: translateX(100px);
  }
}

// --clip
.js .text-anim--clip {
  --text-anim-duration: 0.7s;
  --text-anim-pause: 2s;
  --text-anim-border-width: 2px;

  .text-anim__wrapper {
    overflow: hidden;
    vertical-align: top;

    &::after { // right border
      content: '';
      position: absolute;
      right: 0;
      top: calc(50% - 0.7em);
      height: 1.4em;
      width: var(--text-anim-border-width);
      background-color: var(--color-accent);
    }
  }

  .text-anim__wrapper--pulse {
    &::after {
      animation: text-anim-pulse .8s infinite;
    }
  }

  .text-anim__word {
    opacity: 0;
    padding-right: var(--text-anim-border-width);
  }

  .text-anim__word--in {
    opacity: 1;
  }
}

@keyframes text-anim-pulse {
  0%, 100% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }
}
