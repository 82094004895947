// basic buttons style - we suggest you don't modify this file
// use the 📁custom-style/_buttons.scss file to create your custom buttons

@if variable-exists('cody-base-partials') == false or index($cody-base-partials, 'buttons') {
  .btn { // basic button style
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    text-decoration: none;
    line-height: 1;
    font-size: var(--btn-font-size, 1em);
    padding-top: var(--btn-padding-y, 0.5em);
    padding-bottom: var(--btn-padding-y, 0.5em);
    padding-left: var(--btn-padding-x, 0.75em);
    padding-right: var(--btn-padding-x, 0.75em);
    border-radius: var(--btn-radius, 0.25em);
  }

  // default size variations
  .btn--sm { font-size: var(--btn-font-size-sm, 0.8em); }
  .btn--md { font-size: var(--btn-font-size-md, 1.2em); }
  .btn--lg { font-size: var(--btn-font-size-lg, 1.4em); }

  // button with (only) icon
  .btn--icon { padding: var(--btn-padding-y, 0.5em); }
}